 <!-- Begin page -->
 <div class="accountbg"></div>
 <div class="wrapper-page">

     <div class="card">
         <div class="card-body">
             <a href="#" class="account-logo"><img src="/assets/images/logo.svg" alt=""></a>
             <h5 class="text-center mt-0 m-b-15 thanks-msg">
                Thanks for using Think fit
             </h5>
             <h6 class="updated-msg text-center"><span>Your Password has been Changed Successfully.</span>
             </h6>
            
         </div>
     </div>
 </div>