import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../models/login_model';
import{ AccountService} from'../services/account.service';
  import { from } from 'rxjs';
@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {
  
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 loaderShow: boolean = false
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      email: '',
      id :'',
      name:'',
      dob:'',
      gender:'', 
    }
    this.addValidationError = <Login_model>{
      email: '',
      id :'',
      name:'',
      dob:'',
      gender:'',  
      }
     this.route.params.subscribe(params => {
       this.getId = params['id'];
      //  this.getUser()
     });
     this.getUser();
     this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

  getUser() {
    this.loaderShow=true;
    this.accountService.getUsers(this.getId).then((response) => {
        if(response.code==201){
         this.record=response.result;
         this.loginModel.name=this.record.name;
         this.loginModel.email=this.record.email;
         this.loginModel.dob=this.record.dob;
         this.loginModel.gender=this.record.gender;
         this.loginModel.id=this.record.id;
         this.loaderShow=false;
        }else{
          //this.router.navigate(['/user']);
        }
      
      })
  }

 
  update_user(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.email='';
    this.addValidationError.name='';
    this.addValidationError.dob='';
    this.addValidationError.gender='';
    if(this.loginModel.name==""){
      this.addValidationError.name='Please Enter Name';
      valid=false; 
    }
    if(this.loginModel.email==""){
      this.addValidationError.email='Please Enter Email';
      valid=false; 
    }
    if(this.loginModel.dob==""){
      this.addValidationError.dob='Please Enter DOB';
      valid=false; 
    }
    if(this.loginModel.gender==""){
      this.addValidationError.gender='Please Select Gender';
      valid=false; 
    }
    
    if(valid){
      
      this.accountService.update_user(this.loginModel).then((response)=>{
        if (response.code == 200) {
          this.sucMsg = true; 
          setTimeout (() => {  
           this.sucMsg = false; 
            this.router.navigate(['/user']); 
           }, 2000);
      } else {
          this.errMsg = true;
          this.errorMessage;
              setTimeout (() => {
                 this.errMsg = false;
            this.router.navigate(['/user']); 
          }, 2000);
      }
       
      })
    }
    }
}
