
import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import { Validation_model} from'../../models/Validation_model';
import{ AccountService} from'../../services/account.service';

@Component({
  selector: 'app-manage-coupons',
  templateUrl: './manage-coupons.component.html',
  styleUrls: ['./manage-coupons.component.scss']
})

export class ManageCouponsComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg:string=null;
  errMsg:string=null; 
  successMessage='Record updated Successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
//  start
records:any=[];
updated_record: any=[];
total : number= 0;
totalPages : number= 0;
currentPage:number=1;
page : number = 1;
key: string = 'recommend'; //set default
reverse: boolean = false;
loaderShow: boolean = false;
searchKey: '';
recordcheck=false;
buttonDisable: boolean=false;
fileSelect:boolean = false;
fileSelect_error:boolean = false;
// close
 constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

 ngOnInit(): void {
  this.loginModel=<Login_model>{
    part_image: File= null, 
    page:1,
    sortKey: '',
    title: '',
    valid_from: '',
    valid_to: '',
    discount_type: '',
    discount_value: '',
    terms_condition: '',
    redeem_instructions: '',
    description:'',
    prize_in_fitness_point:'',
    coupon_logo:'',
    tags:'',
  }
  this.addValidationError = <Validation_model>{
    part_image: File= null, 
    title: '',
    valid_from: '',
    valid_to: '',
    discount_type: '',
    discount_value: '',
    terms_condition: '',
    redeem_instructions: '',
    description:'',
    prize_in_fitness_point:'',
    coupon_logo:'',
    tags:'',
    }

   this.getId = this.route.params.subscribe(params => {
     this.getId = params['id'];
   });
   this.isLoggedIn();
   this.Coupons();
  
}
isLoggedIn() {
  this.loginModel.email = localStorage.getItem('email');
  if(this.loginModel.email == null){
  this.router.navigate(['/admin']);
  }
  }
  onFileSelect(event) {
    this.buttonDisable = false;
    if (event.target.files.length > 0) {
      const video_file = <File>event.target.files[0];
      if(video_file.type == 'image/jpeg' || video_file.type == 'image/jpg'  || video_file.type == 'image/png'){
        this.loginModel.part_image = <File>video_file;
        this.fileSelect = false;
      }else{
        this.fileSelect = true;
        this.loginModel.part_image = null;
        this.buttonDisable = true;
      }  
    }
  }
     add_coupon(){
        var valid=true;
        this.addValidationError.part_image_error = '';
        if (this.loginModel.part_image == null) {
          this.addValidationError.part_image_error = 'Please Select Image';
          valid = false;
        }
        this.addValidationError.title='';
        if(this.loginModel.title==""){
          this.addValidationError.title='Please Enter Coupon title';
          valid=false; 
        }
        this.addValidationError.valid_from='';
        if(this.loginModel.valid_from==""){
          this.addValidationError.valid_from='Please Enter Valid Date';
          valid=false; 
        }
        this.addValidationError.valid_to='';
        if(this.loginModel.valid_to==""){
          this.addValidationError.valid_to='Please Enter Expired Date';
          valid=false; 
        }
        this.addValidationError.discount_type='';
        if(this.loginModel.discount_type==""){
          this.addValidationError.discount_type='Please Enter Discount Value';
          valid=false; 
        }
        this.addValidationError.description='';
        if(this.loginModel.description==""){
          this.addValidationError.description='Please Enter description';
          valid=false; 
        }
        this.addValidationError.redeem_instructions='';
        if(this.loginModel.redeem_instructions==""){
          this.addValidationError.redeem_instructions='Please Enter Redeem URL';
          valid=false; 
        }
         this.addValidationError.prize_in_fitness_point='';
        if(this.loginModel.prize_in_fitness_point==""){
          this.addValidationError.prize_in_fitness_point='Please Enter Points Required';
          valid=false; 
        }
        this.addValidationError.tags='';
        if(this.loginModel.tags==""){
          this.addValidationError.tags='Please Enter tags';
          valid=false; 
        }
        
        
        this.addValidationError.discount_value='';
        if(this.loginModel.discount_value==""){
          this.addValidationError.discount_value='Please Enter Discount Value';
          valid=false; 
        }

        this.addValidationError.terms_condition='';
        if(this.loginModel.terms_condition==""){
          this.addValidationError.terms_condition='Please Enter Term & Conditions';
          valid=false; 
        }

        if(valid){
         this.loaderShow=true;
         console.log(this.loginModel);
        var formData: FormData  = new FormData();
        formData.append('part_image', this.loginModel.part_image);
        formData.append('title', this.loginModel.title);
        formData.append('valid_from', this.loginModel.valid_from);
        formData.append('valid_to', this.loginModel.valid_to);
        formData.append('discount_type', this.loginModel.discount_type);
        formData.append('discount_value', this.loginModel.discount_value);
        formData.append('terms_condition', this.loginModel.terms_condition);
        formData.append('prize_in_fitness_point', this.loginModel.prize_in_fitness_point);
        formData.append('redeem_instructions', this.loginModel.redeem_instructions);
        formData.append('description', this.loginModel.description);
        formData.append('tags', this.loginModel.tags);
        

          
          this.accountService.add_coupons(formData).then((response)=>{
           this.loaderShow=false;
            if (response.status == true) {
              this.sucMsg = 'Coupon added Successfully';
              setTimeout (() => { this.sucMsg = '';  }, 3000);
              this.Coupons();
            } else {
              this.router.navigate(['/']);
            }
          })
         }
      }

      // coupon listing start
      Coupons()
      {
       this.loaderShow = true;
        this.loginModel.page = this.currentPage;
        this.accountService.allCoupon(this.loginModel).then((response)=>{
          console.log('coupon response',response);
          if(response.status==true){
            this.record=response.result.rows;
            this.total = response.result.count;
             this.totalPages=Math.ceil( response.result.count/10);
             this.loaderShow = false;
             this.recordcheck=false;
             if(this.total == 0){
               this.record=[];
              this.recordcheck=true;
             }
          }else{
            this.loaderShow = false;
            this.recordcheck=true;
            this.router.navigate(['/']);
          }
        })
      }  
    
      pageChange(currentPage){
        this.currentPage=currentPage;
        if(this.loginModel.searchKey==""){
        this.Coupons();
        }else{
          this.Coupons();
        } 
      }
    
      counter(i: number) {
        return new Array(i);
      }

      search_coupon()
      {
        this.loaderShow = true;
        this.loginModel.page = this.currentPage;
        this.accountService.allCoupon(this.loginModel).then((response)=>{
          if(response.status==true){
            this.record=response.result.rows;
            this.total = response.result.count;
             this.totalPages=Math.ceil( response.result.count/10);
             this.loaderShow = false;
             if(this.total == 0){
              this.recordcheck=true;
             }     
          }else{
            this.loaderShow = false;
            this.recordcheck=true;
          }
        })
      }  


    
      coupon_delete(id,coupon){
        if(confirm("Are you sure to delete Coupon "+coupon+" !")) {
          var valid=true;
          this.loaderShow = true;
         this.accountService.delete_coupon(id).then((response)=>{
          this.loaderShow = false;
            if(response.code==200){
              this.sucMsg = 'Coupon Deleted Successfully';
              setTimeout (() => { this.sucMsg = '';  }, 3000);
              this.Coupons();
            }else{
              this.router.navigate(['/']);
            }
          })
        }
      }
    
      change_status(id,status){
            var valid=true;
            this.loaderShow = true;
            this.accountService.change_equipment(id,status).then((response)=>{
             this.loaderShow = false;
                if(response.code==200){
                  if(status==1){
                    this.successMessage='Equipment Disabled Successfully';
                  }else{
                    this.successMessage='Equipment Enabled Successfully';
                  }
                  //  this.sucMsg = true;
                    this.successMessage;
                    this.Coupons();
                //    setTimeout (() => {  this.sucMsg = false }, 1000);
                }else {
                //    this.errMsg = true;
                    this.router.navigate(['/']);
                  
                }
              })
            
          }


      coupon_update(id) {
        var valid = true;
        this.loginModel.id=id;
        // debugger;
        
        this.accountService.coupon_details(this.loginModel).then((response) => {
          if (response.code == 200) {
            this.updated_record = response.result;
         this.loginModel.title=this.updated_record.title;
         this.loginModel.description=this.updated_record.description;
         this.loginModel.discount_type=this.updated_record.discount_type;
         this.loginModel.discount_value=this.updated_record.discount_value;
         this.loginModel.valid_from=this.updated_record.valid_from;
         this.loginModel.valid_to=this.updated_record.valid_to;
         this.loginModel.id=this.updated_record.id;
         this.loginModel.prize_in_fitness_point=this.updated_record.prize_in_fitness_point;
         this.loginModel.redeem_instructions=this.updated_record.redeem_instructions;
         this.loginModel.terms_condition=this.updated_record.terms_condition;
         this.loginModel.coupon_logo=this.updated_record.coupon_logo;
       
          } else {
            this.errMsg = 'Something went wrong !';
            this.router.navigate(['/manage-coupons']);
          }
        })
      }
     

      update_coupon(){
        var valid=true;
        this.addValidationError.title='';
        if(this.loginModel.title==""){
          this.addValidationError.title='Please Enter Coupon title';
          valid=false; 
        }
        this.addValidationError.valid_from='';
        if(this.loginModel.valid_from==""){
          this.addValidationError.valid_from='Please Enter Valid Date';
          valid=false; 
        }
        this.addValidationError.tags='';
        if(this.loginModel.tags==""){
          this.addValidationError.tags='Please Enter tags';
          valid=false; 
        }
        this.addValidationError.valid_to='';
        if(this.loginModel.valid_to==""){
          this.addValidationError.valid_to='Please Enter Expired Date';
          valid=false; 
        }
        this.addValidationError.discount_type='';
        if(this.loginModel.discount_type==""){
          this.addValidationError.discount_type='Please Enter Discount Value';
          valid=false; 
        }
        this.addValidationError.description='';
        if(this.loginModel.description==""){
          this.addValidationError.description='Please Enter description';
          valid=false; 
        }
        this.addValidationError.redeem_instructions='';
        if(this.loginModel.redeem_instructions==""){
          this.addValidationError.redeem_instructions='Please Enter Redeem URL';
          valid=false; 
        }
         this.addValidationError.prize_in_fitness_point='';
        if(this.loginModel.prize_in_fitness_point==""){
          this.addValidationError.prize_in_fitness_point='Please Enter Points Required';
          valid=false; 
        }
        

        this.addValidationError.discount_value='';
        if(this.loginModel.discount_value==""){
          this.addValidationError.discount_value='Please Enter Discount Value';
          valid=false; 
        }

        this.addValidationError.terms_condition='';
        if(this.loginModel.terms_condition==""){
          this.addValidationError.terms_condition='Please Enter Term & Conditions';
          valid=false; 
        }
        if(valid){
          this.loaderShow=true;
          console.log(this.loginModel);
         var formData: FormData  = new FormData();
         formData.append('part_image', this.loginModel.part_image);
         formData.append('title', this.loginModel.title);
         formData.append('valid_from', this.loginModel.valid_from);
         formData.append('valid_to', this.loginModel.valid_to);
         formData.append('discount_type', this.loginModel.discount_type);
         formData.append('discount_value', this.loginModel.discount_value);
         formData.append('terms_condition', this.loginModel.terms_condition);
         formData.append('prize_in_fitness_point', this.loginModel.prize_in_fitness_point);
         formData.append('redeem_instructions', this.loginModel.redeem_instructions);
         formData.append('description', this.loginModel.description);
         formData.append('tags', this.loginModel.tags);
         
         formData.append('id', this.loginModel.id);
 
           this.accountService.update_coupons(formData).then((response)=>{
            this.loaderShow=false;
             if (response.status == true) {
               this.sucMsg = 'Coupon added Successfully';
               setTimeout (() => { this.sucMsg = '';  }, 3000);
               this.Coupons();
             } else {
               this.router.navigate(['/']);
             }
           })
          }
      }

      // close



      




}



