import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../models/login_model';
import { AccountService } from '../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { DataTablesModule } from 'angular-datatables';



import { Cookie } from 'ng2-cookies';
import { timers } from 'jquery';
@Component({
  selector: 'app-user-enquiry',
  templateUrl: './user-enquiry.component.html',
  styleUrls: ['./user-enquiry.component.scss']
})

export class UserEnquiryComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong';
  record:any=[];
  searchKey:'';
  total : number= 0;
  totalPages : number= 0;
  currentPage:number=1;
  page : number = 1;
  key: string = 'recommend'; //set default
  reverse: boolean = false;
  loaderShow: boolean = false;
  recordcheck:boolean = false;
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      email: '',
      name:'',
      page:1,
    }
   this.userListing();
   this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }
  
  userListing()
  {
    this.loaderShow=true;
    this.loginModel.email = localStorage.getItem('email');
    this.loginModel.name = localStorage.getItem('name');
    this.loginModel.page = this.currentPage;
    this.accountService.users_enquiry(this.loginModel).then((response)=>{
      if(response.code==201){
         this.record=response.result.rows;
         this.total = response.result.count;
         this.totalPages=Math.ceil( response.result.count/10); 
         this.loaderShow=false;
         this.recordcheck=false;
         if(this.total == 0){
          this.recordcheck=true;
         }     
      }else{
        this.router.navigate(['/userListing']);
        this.recordcheck=true;
      }
    })
  }  

  pageChange(currentPage){
    this.currentPage=currentPage;
    if(this.loginModel.searchKey==""){
      this.userListing();
    }else{
      this.userListing();
    } 
  }

  counter(i: number) {
    return new Array(i);
  }

  
  delete_user(id,name){
    if(confirm("Are you sure to delete "+name)) {
      this.loaderShow=true;
    var valid=true;
     this.accountService.deleteEnquiry(id).then((response)=>{
      this.loaderShow = false;
      if(response.code==200){
        this.sucMsg = true;
              this.successMessage='User Enquiry Deleted Successfully';
              this.userListing();
              setTimeout (() => {  this.sucMsg = false }, 1000);
      }else{
        this.errMsg = true;
        this.router.navigate(['/']);
      }
       
      })
   }
  }


  change_status(id,status,name){
      if(status==1){
          if(confirm('Are you sure you want to Mark  '+name+' as Resolved !')){
                this.successMessage;
                this.loaderShow=true;
                this.accountService.StatusEnquiryUser(id,status).then((response)=>{
                if(status==1){
                  this.successMessage='Inquiry Marked as Resolved Successfully';
                }else{
                  this.successMessage='Inquiry Marked as Pending Successfully';
                }
                if(response.code==200){
                  this.sucMsg = true;
                  this.successMessage;
                  this.userListing();
                  setTimeout (() => {  this.sucMsg = false }, 3000);   
                }else{
                  this.errMsg = true;
                  this.router.navigate(['/']);
                }
            })
          }
        }else{
          if(confirm('Are you sure you want to Mark  '+name+' as Pending !')){
            this.successMessage;
            this.loaderShow=true;
            this.accountService.StatusEnquiryUser(id,status).then((response)=>{
            if(status==1){
              this.successMessage='Inquiry Marked as Resolved Successfully';
            }else{
              this.successMessage='Inquiry Marked as Pending Successfully';
            }
            if(response.code==200){
              this.sucMsg = true;
              this.successMessage;
              this.userListing();
              setTimeout (() => {  this.sucMsg = false }, 3000);   
            }else{
              this.errMsg = true;
              this.router.navigate(['/']);
            }
        })
      }

        }
    }
  
   
  }

