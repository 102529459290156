import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { DataTablesModule } from 'angular-datatables';



import { Cookie } from 'ng2-cookies';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
  record:any=[];
  searchKey:'';
  public getId: any = [];
  total : number= 0;
  totalPages : number= 0;
  currentPage:number=1;
  page : number = 1;
  key: string = 'recommend'; //set default
  reverse: boolean = false;
  loaderShow: boolean = false;
  recordcheck:boolean = false;
  sortKey: '';
  sortBy: 'desc'; 
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      email: '',
      name:'',
      page:1,
    }
   this.userListing();
   this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

  sortFunction(sortKey){
    if(this.loginModel.searchKey== ""){
    this.currentPage=1;
    this.userListing();
    }else{ 
      this.loginModel.sortKey =sortKey;
      this.sortBy= 'desc'; 
      this.loginModel.sortBy;
      this.accountService.user_sort(this.loginModel).then((response) => {
        if(response.code==201){
        this.record=response.result.rows;
        this.total = response.result.count;
        this.totalPages=Math.ceil( response.result.count/10);
        this.loaderShow=false;
      }else{
      this.recordcheck=true;
      this.record = true;
      this.total = 0;
      this.totalPages=0;
      this.record = [];
      }
      this.loaderShow = false;
      })
    }
    }


  userListing()
  {
    this.loaderShow=true;
    this.loginModel.page = this.currentPage;
    this.accountService.users(this.loginModel).then((response)=>{
      if(response.code==201){
         this.record=response.result.rows;
         this.total = response.result.count;
         this.totalPages=Math.ceil( response.result.count/10);
         this.loaderShow=false;
         this.recordcheck=false;
         if(this.total == 0){
          this.recordcheck=true;
         }         
      }else{
        this.recordcheck=true;
        //this.router.navigate(['/user']);
      }
    })
  }  

  pageChange(currentPage){
    this.currentPage=currentPage;
    if(this.loginModel.searchKey==""){
      this.userListing();
    }else{
      this.userListing();
    } 
  }

  counter(i: number) {
    return new Array(i);
  }
  
  delete_user(id,email){
    if(confirm("Are you sure to delete "+email)) {
    var valid=true;
     this.accountService.delete_user(id).then((response)=>{
      this.loaderShow = false;
      if(response.code==200){
        this.sucMsg = true;
              this.successMessage='User Deleted Successfully';
              this.userListing();
              setTimeout (() => {  this.sucMsg = false }, 1500);
      }else{
        this.errMsg = true;
        this.router.navigate(['/']);
      }
      })
   }
  }

  change_status(id,status){
    var valid=true;
     this.accountService.change_status(id,status).then((response)=>{
      if(status==1){
        this.successMessage='User Disabled Successfully';
      }else{
        this.successMessage='User Enabled Successfully';
      }
      if(response.status==true){
        this.sucMsg = true;
        this.successMessage;
        this.userListing();
        setTimeout (() => {  this.sucMsg = false }, 1500);   
      }else{
        this.errMsg = true;
        this.router.navigate(['/']);
      }
      })
    
  }

  
   

}
