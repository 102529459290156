import { Component, OnInit } from '@angular/core';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})


export class AboutUsComponent implements OnInit {
  public Editor = ClassicEditor;
  dtOptions: DataTables.Settings = {}; 
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }
  sucMsg:string=null;
  errMsg:string=null; 
  successMessage='Record updated Successfully'; 
  record:any=[];

  ngOnInit(): void {
    this.show_privacy();
    //this.privacy();
    this.loginModel=<Login_model>{
      id: '1',
      description: '',
    }
    this.isLoggedIn();
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }else{
    this.router.navigate(['/show_privacy']);
    }
    }
  about_us(){
      this.accountService.about_us(this.loginModel).then((response)=>{
        if(response.code==200){
          this.sucMsg = 'success';
          setTimeout (() => { this.sucMsg = '';  }, 3000);
          this.show_privacy();
        }else{  
          this.errMsg = 'No Records';
          setTimeout (() => { this.errMsg = '';  }, 3000);
          this.show_privacy();
        }
      }) 
    }
 

    show_privacy(){
      this.accountService.show_about_us(this.loginModel).then((response)=>{
        console.log(response);
        if(response.code==200){
          //debugger;
          this.loginModel.description = response.result[0].description;
        }else{
          this.errMsg = 'Wrong Credentials';
        }
      }) 
    }

}
