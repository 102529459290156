import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import { Validation_model} from'../../models/Validation_model';
import{ AccountService} from'../../services/account.service';


@Component({
  selector: 'app-application-setting',
  templateUrl: './application-setting.component.html',
  styleUrls: ['./application-setting.component.scss']
})
export class ApplicationSettingComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg:boolean=false;
  errMsg:string='false';
 record:any=[];
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }
  ngOnInit(): void {
    this.loginModel=<Login_model>{
      email: '',
    }
     this.isLoggedIn();
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }
}
 
