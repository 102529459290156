<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">About us Editor</li>
                        </ol>
                    </div>
                    <h4 class="page-title">About Us </h4>
                </div>
            </div>
        </div>
       <!-- end page title end breadcrumbrfere -->
       <div class="row">
        <div class="col-md-12">
            <form>
            <div class="card"> 
                
                <div class="card-header">
                    <!-- Success msg html -->
<div class="alert alert-success" role="alert" *ngIf="sucMsg">
    {{ successMessage }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="errMsg">
    {{ errorMessage }}
    </div>
                    <h4> Update About Us </h4>
                </div>
                <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                <angular-editor   type="text" name="description"   [placeholder]="'Enter text here...'" [(ngModel)]="loginModel.description" > </angular-editor> 
             <!-- YOu may also use ckeditor Both are in working condition
                <ckeditor [editor]="Editor"  type="text" name="description" [(ngModel)]="loginModel.description"></ckeditor>
                 -->
                </div>
                    </div> 
                </div>
                <button class="btn a_update_btn btn-primary mt-2" (click)="about_us()">Update</button>
            </div> 
        </form>   
        </div>
    </div> 
</div> <!-- end container --> 
</div>
<app-footer></app-footer>
