        <!-- Footer -->
       
        <script type="text/javascript">
            // Include this file AFTER both jQuery and bootstrap are loaded.
            $.fn.modal.Constructor.prototype.enforceFocus = function() {
              modal_this = this
              $(document).on('focusin.modal', function (e) {
                if (modal_this.$element[0] !== e.target && !modal_this.$element.has(e.target).length 
                && !$(e.target.parentNode).hasClass('cke_dialog_ui_input_select') 
                && !$(e.target.parentNode).hasClass('cke_dialog_ui_input_textarea')
                && !$(e.target.parentNode).hasClass('cke_dialog_ui_input_text')) {
                  modal_this.$element.focus()
                }
              })
            };
        </script>
       
        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        © 2020 ThinkFit.
                    </div>
                </div>
            </div>
        </footer>
        <!-- End Footer -->

