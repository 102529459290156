import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import { Validation_model} from'../../models/Validation_model';
import{ AccountService} from'../../services/account.service';

@Component({
  selector: 'app-add-renewal-period',
  templateUrl: './add-renewal-period.component.html',
  styleUrls: ['./add-renewal-period.component.scss']
})


export class AddRenewalPeriodComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record Added successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

 ngOnInit(): void {
  this.loginModel=<Login_model>{
    renew_exercise: '',
    link :'',
    description:''
  }
  this.addValidationError = <Validation_model>{
    renew_exercise: '',
    link :'',
    description:''
    }

   this.getId = this.route.params.subscribe(params => {
     this.getId = params['id'];
   });
   this.isLoggedIn();
}

isLoggedIn() {
  this.loginModel.email = localStorage.getItem('email');
  if(this.loginModel.email == null){
  this.router.navigate(['/']);
  }
  }
      add_renewal(){
        var valid=true;
        this.addValidationError.renew_exercise='';
        this.addValidationError.link='';
        if(this.loginModel.renew_exercise==""){
          this.addValidationError.renew_exercise='Please Enter Renewal Exercise';
          valid=false; 
        }
        if(this.loginModel.link==""){
          this.addValidationError.link='Please Enter Video Link';
          valid=false; 
        }
        if(valid){
          this.accountService.add_renew(this.loginModel).then((response)=>{
            if (response.status == true) {
              this.sucMsg = true;
              setTimeout (() => { this.router.navigate(['/renewal-period-listing']);  }, 1500);
            } else {
              this.errMsg = true;
              this.router.navigate(['/']);
            }
          })
         }
      }
}
