import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import{ AccountService} from'../../services/account.service';
import { from } from 'rxjs';
@Component({
  selector: 'app-edit-renewal-period',
  templateUrl: './edit-renewal-period.component.html',
  styleUrls: ['./edit-renewal-period.component.scss']
})

export class EditRenewalPeriodComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 loaderShow: boolean = false
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      productivity_exercise: '',
      quotes :'',
      description:''
    }
    this.addValidationError = <Login_model>{
      productivity_exercise: '',
      quotes :'',
      description:''
      }
     this.route.params.subscribe(params => {
       this.getId = params['id'];
      //  this.getUser()
     });
     this.getRenewal_period();
     this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

  getRenewal_period() {
    this.loaderShow=true;
    this.accountService.getRenewal_period(this.getId).then((response) => {
        if(response.code==201){
         this.record=response.result;
         this.loginModel.renew_exercise=this.record.renew_exercise;
         this.loginModel.link=this.record.link;
         this.loginModel.description=this.record.description;
         this.loginModel.id=this.record.id;
         this.loaderShow=false;
        }else{
          this.successMessage='No Record Found !';
        }
      })
  }

 
  update_renewal_period(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.renew_exercise	='';
    this.addValidationError.link='';
    if(this.loginModel.renew_exercise==""){
      this.addValidationError.renew_exercise='Please Enter Renewal name';
      valid=false; 
    }
    if(this.loginModel.link==""){
      this.addValidationError.link='Please Enter link';
      valid=false; 
    }
    
    if(valid){ 
      this.accountService.update_renewal_period(this.loginModel).then((response)=>{
        if (response.code == 200) {
          this.sucMsg=true;
          setTimeout (() => {  
           this.sucMsg = false; 
            this.router.navigate(['/renewal-period-listing']); 
           }, 2000);
      } else {
          this.errMsg = true;
          this.errorMessage;
              setTimeout (() => {
                 this.sucMsg = false;
            this.router.navigate(['/renewal-period-listing']); 
          }, 2000);
      }
        
      })
    }
    }
}


