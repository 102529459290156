import { Component, OnInit } from '@angular/core';
import { Login_model } from '../../models/login_model';
import { AccountService} from '../../services/account.service';
import{ActivatedRoute,Router} from '@angular/router';
import {Headers,Http} from '@angular/http';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }
  sucMsg: boolean = false;
  errMsg:boolean = false;
  sucMsg1: boolean = false;
  errMsg1:boolean = false; 
  successMessage='Record updated successfully'; 
  successMessage1='';
  errorMessage1='Something went wrong';
  errorMessage='Something went wrong'; 
  //sucMsg1:string='';

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      password:'',
      new_email:'' 
    }
    this.addValidationError = <Login_model>{
    c_password:'' 
    }
    this.isLoggedIn();
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }else{
    this.router.navigate(['/change_password']);
    }
    }
  
  update_email(){
    var valid=true;
    this.addValidationError.new_email='';
    if(this.loginModel.new_email==""){
      this.addValidationError.new_email='Please Enter Email';
      valid=false; 
    }
    if(valid){
      this.accountService.update_email(this.loginModel).then((response)=>{
        if (response.code == 200) {
          this.loginModel.new_email='';
          this.sucMsg1 = true;
              this.successMessage1='Email Updated Successfully';
              this.loginModel.email=this.loginModel.new_email;
          localStorage.setItem('email',this.loginModel.new_email);
              setTimeout (() => {  this.sucMsg1 = false }, 1500);
          this.sucMsg;
           setTimeout (() => {  this.sucMsg1 = false;  }, 2000);
         } else {
           this.sucMsg = true;
                 setTimeout (() => {  this.sucMsg1 = false }, 2000);
         }
      })
    }
    }


  update_password(){
    console.log(this.loginModel);
    var valid=true;
    this.addValidationError.password='';
    if(this.loginModel.password==""){
      this.addValidationError.password='Please Enter Password';
      valid=false; 
    }
    if(this.loginModel.c_password==""){
      this.addValidationError.c_password='Please Enter Confirm Password';
      valid=false; 
    }
    if(this.loginModel.password!=this.loginModel.c_password){
      this.addValidationError.c_password="Password does not match";
      valid=false;
    }else{
      this.addValidationError.c_password="";
      valid=true;
    }
    if(valid){
      this.accountService.update_password(this.loginModel).then((response)=>{
        if (response.code == 200) {
          this.loginModel.password='';
          this.loginModel.c_password='';
          this.sucMsg = true;
              this.successMessage='Password Changed Successfully';
              setTimeout (() => {  this.sucMsg = false }, 1500);
          this.sucMsg;
           setTimeout (() => {  this.sucMsg = false;  }, 2000);
         } else {
           this.sucMsg = true;
                 setTimeout (() => {  this.sucMsg = false }, 2000);
         }
      })
    }
    }


}
