<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">Contact Us</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Contact Us</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Contact Us</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <h6>Address :</h6>
                            </div>
                            <div class="col-md-8">
                                <p>#256, New street India</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <h6>Phone No :</h6>
                            </div>
                            <div class="col-md-8">
                                <p>25635-69856</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <h6>Email :</h6>
                            </div>
                            <div class="col-md-8">
                                <p>example@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- end container -->
</div>
<app-footer></app-footer>