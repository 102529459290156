import { Component, OnInit } from '@angular/core';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import{ AccountService} from'../../services/account.service';


@Component({
  selector: 'app-edit-equipment',
  templateUrl: './edit-equipment.component.html',
  styleUrls: ['./edit-equipment.component.scss']
})

export class EditEquipmentComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong';
 record:any=[];
 loaderShow: boolean = false
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      equipment_type: '',
    }
    this.addValidationError = <Login_model>{
      equipment_type: '',
      }
     this.route.params.subscribe(params => {
       this.getId = params['id'];
     });
     this.getEquipment();
     this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

    getEquipment() {
    this.loaderShow=true;
    this.accountService.getEquipment(this.getId).then((response) => {
        if(response.code==200){
          // debugger;
          console.log('test=>',response.result)
         this.record=response.result;
         this.loginModel.equipment_type=this.record.equipment_type;
         this.loginModel.id=this.record.id;
         this.loaderShow=false;
        
        }else{
          this.errMsg = true;
        }
      })
  }
 
  update_equipment(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.equipment_type	='';
    if(this.loginModel.equipment_type==""){
      this.addValidationError.equipment_type='Please Enter Equipment Type';
      valid=false; 
    }
    if(valid){ 
      this.accountService.update_equipment_url(this.loginModel).then((response)=>{
        if (response.code == 200) {
         this.sucMsg=true;
          setTimeout (() => {  this.sucMsg = false;
            this.router.navigate(['/show-equipment']);
          }, 2000);
        } else {
          this.errMsg = true;
                this.errorMessage;
                setTimeout (() => {  this.sucMsg = false }, 2000);
        }
      })
    }
    }
}




