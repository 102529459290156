import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersComponent } from './components/users/users.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProjectInnerComponent } from './components/project-inner/project-inner.component';
//import { FaqComponent } from './components/faq/faq.component';
import { ForgetComponent } from './components/forget/forget.component';
import { EdituserComponent } from './edituser/edituser.component';
import { NewtestComponent } from './components/newtest/newtest.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ViewUserComponent } from './view-user/view-user.component';


//import { DataTablesModule } from 'angular-datatables';
import { BrowserModule } from '@angular/platform-browser';

import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UserEnquiryComponent } from './user-enquiry/user-enquiry.component';
import { ApplicationSettingComponent } from './components/application-setting/application-setting.component';
import { AddExerciseComponent } from './components/add-exercise/add-exercise.component';
import { ViewExerciseComponent } from './components/view-exercise/view-exercise.component';
import { DetailedViewExerciseComponent } from './components/detailed-view-exercise/detailed-view-exercise.component';
import { AddRenewalPeriodComponent } from './components/add-renewal-period/add-renewal-period.component';
import { AddProductivityComponent } from './components/add-productivity/add-productivity.component';
import { ProductivityListingComponent } from './components/productivity-listing/productivity-listing.component';
import { RenewalPeriodListingComponent } from './components/renewal-period-listing/renewal-period-listing.component';
import { WearingComponent } from './components/wearing/wearing.component';
import { AddWearingComponent } from './components/add-wearing/add-wearing.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { EditProductivityListingComponent } from './components/edit-productivity-listing/edit-productivity-listing.component';
import { EditRenewalPeriodComponent } from './components/edit-renewal-period/edit-renewal-period.component';
import { EditWearingComponent } from './components/edit-wearing/edit-wearing.component';
import { AddInterruptionFocusTipsComponent } from './components/add-interruption-focus-tips/add-interruption-focus-tips.component';
import { ShowInterruptionComponent } from './components/show-interruption/show-interruption.component';
import { EditInterrruptionComponent } from './components/edit-interrruption/edit-interrruption.component';
import { AddEquipmentComponent } from './components/add-equipment/add-equipment.component';
import { ShowEquipmentComponent } from './components/show-equipment/show-equipment.component';
import { EditEquipmentComponent } from './components/edit-equipment/edit-equipment.component';
import { ViewExerciseListingComponent } from './components/view-exercise-listing/view-exercise-listing.component';
import { EditViewExerciseComponent } from './components/edit-view-exercise/edit-view-exercise.component';
import { ThanksMsgComponent } from './thanks-msg/thanks-msg.component';
import { ShowInterruptionFeedbackComponent } from './components/show-interruption-feedback/show-interruption-feedback.component';
import { EmailVarifyComponent } from './components/email-varify/email-varify.component';
import { ManageCouponsComponent } from './components/manage-coupons/manage-coupons.component';
import { ViewOfferComponent } from './components/view-offer/view-offer.component';
import { AdminRecoverPasswordComponent } from './components/admin-recover-password/admin-recover-password.component';



const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "vv", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "user", component: UsersComponent },
  { path: "project", component: ProjectsComponent },
  { path: "manage-coupons", component: ManageCouponsComponent },
  { path: "view-offer/:coupon_id", component: ViewOfferComponent },
  { path: "application-setting", component: ApplicationSettingComponent },
  { path: "contact", component: ContactComponent },
  { path: "project-inner", component: ProjectInnerComponent },
  //{path:"faq", component:FaqComponent},
  { path: "forget", component: ForgetComponent },
  { path: "edit_user/:id", component: EdituserComponent },
  { path: "change_password", component: ChangePasswordComponent },
  { path: "newtest", component: NewtestComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "user-enquiry", component: UserEnquiryComponent },
  { path: "view-user/:id", component: ViewUserComponent },
  { path: "add-exercise", component: AddExerciseComponent },
  { path: "view-exercise", component: ViewExerciseComponent },
  { path: "detailed-view-exercise/:id", component: DetailedViewExerciseComponent },
  { path: "add-renewal-period", component: AddRenewalPeriodComponent },
  { path: "add-productivity", component: AddProductivityComponent },
  { path: "productivity-listing", component: ProductivityListingComponent },
  { path: "renewal-period-listing", component: RenewalPeriodListingComponent },
  { path: "add-wearing", component: AddWearingComponent },
  { path: "show-wearing", component: WearingComponent },
  { path: "recover-password/:token", component: RecoverPasswordComponent },
  { path: "edit-productivity/:id", component: EditProductivityListingComponent },
  { path: "edit-renewalperiod/:id", component: EditRenewalPeriodComponent },
  { path: "edit-wearing/:id", component: EditWearingComponent },
  { path: "add-interruption", component: AddInterruptionFocusTipsComponent },
  { path: "show-interruption", component: ShowInterruptionComponent },
  { path: "edit-interruption/:id", component: EditInterrruptionComponent },

  { path: "add-equipment", component: AddEquipmentComponent },
  { path: "show-equipment", component: ShowEquipmentComponent },
  { path: "edit-equipment/:id", component: EditEquipmentComponent },
  { path: "ViewExerciseListing", component: ViewExerciseListingComponent },
  { path: "edit-exercise/:id", component: EditViewExerciseComponent },
  { path: "thanks-message", component: ThanksMsgComponent },
  { path: "showInterruptionFeedbck", component: ShowInterruptionFeedbackComponent },
  { path: "email-varify/:token", component: EmailVarifyComponent },

  { path: 'admin-recover-password/:token', component: AdminRecoverPasswordComponent }


  // {path:"get_focus_tips" ,component:AddProductivityComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserModule, HttpClientModule, AngularEditorModule, CKEditorModule,],

  exports: [RouterModule],
  providers: [],
  bootstrap: [UsersComponent],
})
export class AppRoutingModule { }
