<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/view-exercise']">View Exercise List</a></li>
                            <li class="breadcrumb-item active">Update Detailed Exercise</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Update Exercise & Points</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg1">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg1">
                            {{ errorMessage }}
                            </div>
                        <form class="form-horizontal m-t-20" action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Exercise</label>
                                        <input type="hidden" [(ngModel)]="loginModel.id" name="id" required=""
                                        class="form-control">
                            <input type="text" [(ngModel)]="loginModel.exercise" name="exercise" required=""
                                        class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label>Body Section</label>
                            <input type="text" [(ngModel)]="loginModel.body_section" name="body_section" required=""
                                        class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label> Video Link</label>
                            <input type="text" [(ngModel)]="loginModel.video_link" name="video_link" required=""
                                        class="form-control">
                                    </div>
                                    <label>Equipment Used</label>
                            <div class="form-group">
                                <div class="form-check" *ngFor="let cat of record;let j=index">
                                    <span>
                                        <input class="form-check-input" 
                                        type="checkbox"  [checked]="cat.availability=='Yes'" (change)="onChangeValue(j,cat.id)">
                                        <label class="form-check-label" for="{{cat.equipment_type}}">
                                            {{cat.equipment_type}}
                                        </label> 
                                    </span>
                               </div>

                            </div>
                                
                                </div>
                                <div class="col-md-6">
                                    <div class="table-responsive table_area">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th><div class="respons_level">Fitness Level</div></th>
                                                    <th><div class="respons_input">Reps</div></th>
                                                    <th><div class="respons_input">Points</div></th>
                                                 </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Super Fit</td>
                                                    <td><input type="number" [(ngModel)]="loginModel.super_fit"
                                                            name="super_fit" value="loginModel.super_fit" class="form-control">
                                                    </td>

                                                    <td><input type="number" [(ngModel)]="loginModel.super_fit1"
                                                            name="super_fit1" value="loginModel.super_fit1" class="form-control">
                                                   </td>    
                                                </tr>
                                                <tr>
                                                    <td>Fit</td>
                                                    <td><input type="number" [(ngModel)]="loginModel.fit"
                                                            name="fit" value="loginModel.fit" class="form-control">
                                                    </td>

                                                    <td><input type="number" [(ngModel)]="loginModel.fit1"
                                                            name="fit1" value="loginModel.fit1" class="form-control">
                                                   </td>    
                                                </tr>
                                                <tr>
                                                    <td>UnFit</td>
                                                    <td><input type="number" [(ngModel)]="loginModel.unfit"
                                                            name="unfit" value="loginModel.unfit" class="form-control">
                                                    </td>

                                                    <td><input type="number" [(ngModel)]="loginModel.unfit1"
                                                            name="unfit1" value="loginModel.unfit1" class="form-control">
                                                   </td>    
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="sub_btn">
                                <!-- <button class="btn btn-primary" type="button">Add</button> -->
                                <button class="btn btn-primary" (click)="update_exercise()" type="button">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>