<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/productivity-listing']">Productivity Listing</a></li>
                            <li class="breadcrumb-item active">Add Productivity Exercise</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Add Productivity</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <form class="form-horizontal m-t-20" action="">
                            <div class="row">
                                <div class="col-md-6">

                                    <div class="form-group">
                                        <label>Interruption Type</label>
                                        <select id="productivity_exercise" name="productivity_exercise"
                                            class="form-control" (change)="onValueChange($event.target.value)">
                                            <option value="">SELECT VALUE</option>
                                            <option *ngFor="let order of recordS; " value="{{order.id}}">
                                                {{ order.focus_tip }}
                                            </option>
                                        </select>
                                    </div>
                                  <span class="err_msg" 
                                        *ngIf="addValidationError.productivity_exercise">{{addValidationError.productivity_exercise}}</span>
                                    <label>Focus tip Description(Quotes)</label>
                                    <div class="form-group">
                                        <!-- <input type="text" [(ngModel)]="loginModel.quotes" name="quotes"
                                        class="form-control" required> -->
                                        <textarea name="quotes" [(ngModel)]="loginModel.quotes"
                                            ng-model="myTextarea"></textarea>
                                       <span class="err_msg" *ngIf="addValidationError.quotes">{{addValidationError.quotes}}</span>

                                    </div>
                                    <label>Description(optional)</label>
                                    <div class="form-group">
                                        <!-- <input type="text" [(ngModel)]="loginModel.description" name="description"
                                        class="form-control" required> -->
                                        <textarea name="description" [(ngModel)]="loginModel.description"
                                            ng-model="myTextarea"></textarea>

                                    </div>
                                </div>

                            </div>

                            <div class="sub_btn">
                                <!-- <button class="btn btn-primary" type="button">Add</button> -->
                                <button class="btn btn-primary" (click)="add_productivity()" type="button">Add
                                    Productivity</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>