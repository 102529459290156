<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['#']">Africause</a></li>
                            <li class="breadcrumb-item active">Project</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Project</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
                <a [routerLink]="['#']" class="head-btn" data-toggle="modal" data-target="#addproject">Add +</a>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
            <div class="col-md-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/project-inner']"></a>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
                        <g id="outline">
                            <path d="M14,41v6H42V41Zm8,4H16V43h6Zm18-2v2H24V43Z" />
                            <path d="M47,47a3,3,0,1,0-3-3A3,3,0,0,0,47,47Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,47,43Z" />
                            <path
                                d="M57.438,22.264l-4.187,1.573-5.544-5.544a1,1,0,0,0-1.154-.188l-5.407,2.7c-1.1-.819-3.84-2.309-9.482-2.761.09-.231.178-.452.27-.69a1.006,1.006,0,0,0-.039-.806L28.865,10.5a3.306,3.306,0,0,0-4.146-1.457,2.9,2.9,0,0,0-1.88,1.522,4.653,4.653,0,0,0,.212,3.753l.853,2.557c-.322.515-.948,1.494-1.743,2.633-.535.135-1.067.265-1.534.363-1.668-3.522-6.589-6.286-10.731-5.864A8.849,8.849,0,0,0,2,21.922,9.312,9.312,0,0,0,4.191,29H4v2a4,4,0,0,0,4,4H25v2H10V55H21.618l2-4H40.382l2,4H54V37H39V35H56a4,4,0,0,0,4-4V29H58.876l1.084-.464a3.381,3.381,0,0,0-2.522-6.272Zm-31.489-5.58-1.025-3.067a3.063,3.063,0,0,1-.28-2.191c.066-.139.205-.338.705-.488a1.348,1.348,0,0,1,1.756.509l2.8,5.605C27.816,22.418,24.981,28.327,23.893,29H18.519c-.253-.518-.454-1.651,1.188-3.293a50.558,50.558,0,0,0,6.15-8.192A1,1,0,0,0,25.949,16.684ZM4.2,24.731l3.045-.761-.486-1.94-2.773.694c0-.214,0-.425.013-.645.008-.1.026-.205.04-.308l3.206-.8-.486-1.94L4.8,19.52a7.041,7.041,0,0,1,5.3-3.526,8.513,8.513,0,0,1,3.955.673,3.187,3.187,0,0,1-1.625,2.509l1.132,1.648a5.387,5.387,0,0,0,2.357-3.182A7.385,7.385,0,0,1,19.046,21.3a.988.988,0,0,0,1.1.689c.062-.009.136-.023.2-.034a27.672,27.672,0,0,1-2.051,2.338C16.479,26.107,16.17,27.772,16.418,29H7.268A6.647,6.647,0,0,1,4.2,24.731ZM20.382,53H12V51h9.382ZM52,53H43.618l-1-2H52Zm0-14V49H12V39ZM37,37H27V35H37Zm19-4H8a2,2,0,0,1-2-2H58A2,2,0,0,1,56,33ZM45.845,28.259l.741.741H44.61ZM59.167,26.7,53.8,29H49.414l-2.27-2.27.8-2.414-1.9-.632-.883,2.649L40.723,29h-14.3a49.168,49.168,0,0,0,4.465-9h.048c4.83.312,7.389,1.425,8.54,2.112l-2.183,2.183,1.414,1.414,2.885-2.885L46.8,20.217l5.375,5.375.927,1.855,1.79-.894-.5-1.007,3.737-1.4a1.427,1.427,0,0,1,.492-.092,1.382,1.382,0,0,1,.547,2.651Z" />
                            <path
                                d="M12.021,22.205a2.689,2.689,0,0,1-1.477,2.905l.912,1.78A4.72,4.72,0,0,0,13.979,21.8Z" />
                        </g>
                    </svg>
                    <p>Child Care</p>
                </div>
            </div>
        </div>
    </div> <!-- end container -->
</div>
<app-footer></app-footer>

<div id="addproject" class="modal fade" role="dialog">
    <div class="modal-dialog  modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add Project</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="from-group">
                        <label for="">Project Name</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="from-group">
                        <label for="">Choose Picture</label>
                        <input type="file" class="form-control">
                    </div>
                    <div class="from-group">
                        <label for="">Description</label>
                       <textarea name="" id="" class="form-control"></textarea>
                    </div>
                    <button class="login_btn mt-4">+ Add</button>
                </form>
            </div>
        </div>
    </div>
</div>