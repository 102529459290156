
import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import { Validation_model} from'../../models/Validation_model';
import{ AccountService} from'../../services/account.service';


@Component({
  selector: 'app-view-offer',
  templateUrl: './view-offer.component.html',
  styleUrls: ['./view-offer.component.scss']
})
export class ViewOfferComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg:string=null;
  errMsg:string=null; 
  successMessage='Record updated Successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 
//  start
records:any=[];
code_record: any=[];
total : number= 0;
totalPages : number= 0;
currentPage:number=1;
page : number = 1;
key: string = 'recommend'; //set default
reverse: boolean = false;
loaderShow: boolean = false;
searchKey: '';
recordcheck=false;
// close
 constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

 ngOnInit(): void {
  this.loginModel=<Login_model>{
    title: '',
    valid_from: '',
    valid_to: '',
    discount_type: '',
    discount_value: '',
    description:'',
    updated_code:'',
    coupon_id:'',
  }
  this.addValidationError = <Validation_model>{
    title: '',
    valid_from: '',
    valid_to: '',
    discount_type: '',
    discount_value: '',
    description:'',
    code:'',
    updated_code:'',
    update_id:'',
    }

   this.route.params.subscribe(params => {
     this.getId = params['coupon_id'];
   });
   this.loginModel.coupon_id=this.getId;
   this.isLoggedIn();
   this.view_coupon_details(this.getId);
   this.Codes();
  
}
isLoggedIn() {
  this.loginModel.email = localStorage.getItem('email');
  if(this.loginModel.email == null){
  this.router.navigate(['/admin']);
  }
  }

  view_coupon_details(id) {
    var valid = true;
    this.loginModel.id=this.getId;
    this.accountService.coupon_details(this.loginModel).then((response) => {
      if (response.code == 200) {
        this.record = response.result;
      } else {
        this.errMsg = 'Something went wrong !';
        this.router.navigate(['/view-offer/'+this.getId]);
      }
    })
  }

  add_code(){
    var valid=true;
    this.addValidationError.code='';
    
    if(this.loginModel.code==""){
      this.addValidationError.code='Please Enter Coupon code';
      valid=false; 
    }
    if(valid){
      this.accountService.add_code(this.loginModel).then((response)=>{
        this.loaderShow=false;
        if (response.status == true) {
          this.sucMsg = 'Code added Successfully';
          setTimeout (() => { this.sucMsg = '';  }, 3000);
          this.Codes();
        } else {
          this.router.navigate(['/view-offer/'+this.getId]);
        }
      })
     }
  }

   // coupon listing start
   Codes()
   {
    this.loaderShow = true;
     this.loginModel.page = this.currentPage;
     this.accountService.getallCode(this.loginModel).then((response)=>{
       console.log('coupon response',response);
       if(response.status==true){
         this.code_record=response.result.rows;
         this.total = response.result.count;
          this.totalPages=Math.ceil( response.result.count/10);
          this.loaderShow = false;
          this.recordcheck=false;
          if(this.total == 0){
            this.code_record=[];
           this.recordcheck=true;
          }
       }else{
         this.loaderShow = false;
         this.recordcheck=true;
         this.router.navigate(['/view-offer/'+this.getId]);
       }
     })
   }  
 
   pageChange(currentPage){
     this.currentPage=currentPage;
     if(this.loginModel.searchKey==""){
     this.Codes();
     }else{
       this.Codes();
     } 
   }
 
   counter(i: number) {
     return new Array(i);
   }

   code_delete(id,Code){
    if(confirm("Are you sure to delete Code "+Code+" !")) {
      var valid=true;
      this.loaderShow = true;
     this.accountService.delete_code(id).then((response)=>{
      this.loaderShow = false;
        if(response.code==200){
          this.sucMsg = 'Code Deleted Successfully';
          setTimeout (() => { this.sucMsg = '';  }, 3000);
          this.Codes();
        }else{
          this.router.navigate(['/view-offer/'+this.getId]);
        }
      })
    }
  }

  code_update(id,code){
    // debugger;
    this.loginModel.update_id=id;
    this.loginModel.updated_code=code;
    console.log(this.loginModel);
  }

  change_codes_status(id,status){
    var valid=true;
     this.accountService.change_code_status(id,status).then((response)=>{
      if(status==1){
        this.sucMsg='Code Disabled Successfully';
      }else{
        this.sucMsg='Code Enabled Successfully';
      }
      if(response.status==true){
        this.successMessage;
        this.Codes();
        setTimeout (() => {  this.sucMsg = '' }, 1500);   
      }else{
        this.errMsg='Something Went Wrong';
        this.router.navigate(['/view-offer/'+this.getId]);
      }
      })
  }

  update_code_details(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.updated_code	='';
    if(this.loginModel.updated_code==""){
      this.addValidationError.updated_code='Please Enter Coupon Code';
      valid=false; 
    }
    if(valid){ 
      // debugger;
      this.accountService.update_code_detail(this.loginModel).then((response)=>{
        if(response.status==true){
          this.successMessage;
          this.Codes();
          this.sucMsg='Code Updated Successfully';
          setTimeout (() => {  this.sucMsg = '' }, 1500);   
        }else{
          this.errMsg='Something Went Wrong';
          this.router.navigate(['/view-offer/'+this.getId]);
        }
      })
    }
    }



}
