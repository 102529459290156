import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from  '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import {LocationStrategy,PathLocationStrategy, HashLocationStrategy} from '@angular/common';
import { BaseHttpService } from './services/base-http.service';
import { AccountService } from './services/account.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UsersComponent } from './components/users/users.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProjectInnerComponent } from './components/project-inner/project-inner.component';
//import { FaqComponent } from './components/faq/faq.component';
import { ForgetComponent } from './components/forget/forget.component';
import { EdituserComponent } from './edituser/edituser.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TestComponent } from './test/test.component';
import { TesterComponent } from './tester/tester.component';
import { NewtestComponent } from './components/newtest/newtest.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UserEnquiryComponent } from './user-enquiry/user-enquiry.component';
import { ViewUserComponent } from './view-user/view-user.component';
//import { ApplicationSettingsComponent } from './components/application-settings/application-settings.component';
import { AddExerciseComponent } from './components/add-exercise/add-exercise.component';
import { ViewExerciseComponent } from './components/view-exercise/view-exercise.component';
import { DetailedViewExerciseComponent } from './components/detailed-view-exercise/detailed-view-exercise.component';
import { AddProductivityComponent } from './components/add-productivity/add-productivity.component';
import { AddRenewalPeriodComponent } from './components/add-renewal-period/add-renewal-period.component';
import { ProductivityListingComponent } from './components/productivity-listing/productivity-listing.component';
import { RenewalPeriodListingComponent } from './components/renewal-period-listing/renewal-period-listing.component';
import { WearingComponent } from './components/wearing/wearing.component';
import { AddWearingComponent } from './components/add-wearing/add-wearing.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { EditProductivityListingComponent } from './components/edit-productivity-listing/edit-productivity-listing.component';
import { EditRenewalPeriodComponent } from './components/edit-renewal-period/edit-renewal-period.component';
import { EditWearingComponent } from './components/edit-wearing/edit-wearing.component';
import { AddInterruptionFocusTipsComponent } from './components/add-interruption-focus-tips/add-interruption-focus-tips.component';
import { ShowInterruptionComponent } from './components/show-interruption/show-interruption.component';
import { EditInterrruptionComponent } from './components/edit-interrruption/edit-interrruption.component';
import { AddEquipmentComponent } from './components/add-equipment/add-equipment.component';
import { EditEquipmentComponent } from './components/edit-equipment/edit-equipment.component';
import { ShowEquipmentComponent } from './components/show-equipment/show-equipment.component';
import { ViewExerciseListingComponent } from './components/view-exercise-listing/view-exercise-listing.component';
import { ApplicationSettingComponent } from './components/application-setting/application-setting.component';
import { EditViewExerciseComponent } from './components/edit-view-exercise/edit-view-exercise.component';
import { ThanksMsgComponent } from './thanks-msg/thanks-msg.component';
import { ShowInterruptionFeedbackComponent } from './components/show-interruption-feedback/show-interruption-feedback.component';
import { EmailVarifyComponent } from './components/email-varify/email-varify.component';
import { ManageCouponsComponent } from './components/manage-coupons/manage-coupons.component';
import { ViewOfferComponent } from './components/view-offer/view-offer.component';
import { AdminRecoverPasswordComponent } from './components/admin-recover-password/admin-recover-password.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    UsersComponent,
    ProjectsComponent,
    ContactComponent,
    ProjectInnerComponent,
    //FaqComponent,
    ForgetComponent,
    EdituserComponent,
    ChangePasswordComponent,
    AboutUsComponent,
    TestComponent,
    TesterComponent,
    NewtestComponent,
    PrivacyPolicyComponent,
    UserEnquiryComponent,
    ViewUserComponent,
    //ApplicationSettingsComponent,
    AddExerciseComponent,
    ViewExerciseComponent,
    DetailedViewExerciseComponent,
    AddProductivityComponent,
    AddRenewalPeriodComponent,
    ProductivityListingComponent,
    RenewalPeriodListingComponent,
    WearingComponent,
    AddWearingComponent,
    RecoverPasswordComponent,
    EditProductivityListingComponent,
    EditRenewalPeriodComponent,
    EditWearingComponent,
    AddInterruptionFocusTipsComponent,
    ShowInterruptionComponent,
    EditInterrruptionComponent,
    AddEquipmentComponent,
    EditEquipmentComponent,
    ShowEquipmentComponent,
    ViewExerciseListingComponent,
    ApplicationSettingComponent,
    EditViewExerciseComponent,
    ThanksMsgComponent,
    ShowInterruptionFeedbackComponent,
    EmailVarifyComponent,
    ManageCouponsComponent,
    ViewOfferComponent,
    AdminRecoverPasswordComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    AngularEditorModule,
    CKEditorModule,
    ReactiveFormsModule
  ],
  providers: [AccountService,BaseHttpService, { provide: LOCALE_ID, useValue: 'nl-NL' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
