import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { Validation_model } from '../../models/Validation_model';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-edit-view-exercise',
  templateUrl: './edit-view-exercise.component.html',
  styleUrls: ['./edit-view-exercise.component.scss']
})
export class EditViewExerciseComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Validation_model;
  private headers = new Headers();
  constructor(private accountService: AccountService, private route: ActivatedRoute, private router: Router, private http: Http) { }
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong';
  record: any = [];
  record1: any = [];
  record2: any =[];
  multi_record: any = [];
  detailed_record: any = [];
  getId: string;
  sucMsg1: boolean = false;
  errMsg1: boolean = false;
  valid: boolean = true;
  checked: boolean = false;

  ngOnInit(): void {
    this.loginModel = <Login_model>{
      email: '',
      name: '',
      searchKey: '',
      id :'',
      Equip: []
    }

    this.route.params.subscribe(params => {
      this.getId = params['id'];
      //  this.getUser()
    });
    this.isLoggedIn()
    this.get_fitness_level(this.getId);
    this.get_exercise_based_equipment_list(this.getId);
    this.user_detailed_view(this.getId);
    
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if (this.loginModel.email == null) {
      this.router.navigate(['/']);
    }
  }

  onChangeValue(index,cat) { // Use appropriate model type instead of any 
    // debugger;
    this.record.forEach((element,id) => {
      if (index == id) {
        if (element.availability == 'Yes') {
         element.availability='1';
        }else{
          element.availability='Yes';
        }
      }
    });
  
  }

  // onChangeCategory(index, cat) { // Use appropriate model type instead of any
   
  //   if (this.loginModel.Equip[index] == undefined || this.loginModel.Equip[index] == "") {
  //    // console.log(cat);
  //     this.loginModel.Equip[index] = cat;
  //   } else {
  //     this.loginModel.Equip[index] = "";
  //   }
  // }



  //Get all exercise checkbox question with checked value on "Availablity=yes"
  get_exercise_based_equipment_list(id) {
    this.accountService.get_exercise_based_equipment_lists(id).then((response) => {
      if (response.status == true) {
        this.record = response.result;
      } else {
        this.successMessage = 'Please Add Equipments';
      }
    })
  }


  //Update exercise
  update_exercise() {
    var valid = true;
    if (valid) {
      this.loginModel.Equip=this.record;
      this.accountService.update_exercises(this.loginModel).then((response) => {
        if (response.status == true) {
          this.sucMsg1 = true;
          setTimeout (() => { this.sucMsg1 = false; this.router.navigate(['/view-exercise']);  }, 2000);
        } else {
          this.errMsg1 = true;
          setTimeout (() => {  this.errMsg1 = false; this.router.navigate(['/view-exercise']);  }, 2000);
        }
      })
    } else {
      alert('Enter All Input Fields');
    }
  }




  user_detailed_view(id) {
    this.accountService.user_detailed_views(id).then((response) => {
      if (response.status == true) {
        this.record2 = response.result;
        this.loginModel.exercise = this.record2.exercise;
        this.loginModel.body_section = this.record2.body_section;
        this.loginModel.video_link = this.record2.video_link;
        this.loginModel.id = this.record2.id;
        this.sucMsg = true;
      } else {
        this.errMsg = false;
      }
    })
  }



  get_fitness_level(id) {
    this.accountService.get_fitness_levelss(id).then((response) => {
      if (response.status == true) {
        this.record1 = response.result;
        this.loginModel.super_fit = this.record1[0].reps; //super fit reps
        this.loginModel.super_fit1 = this.record1[0].points;  //super fit points
        this.loginModel.fit = this.record1[1].reps;  //Fit reps
        this.loginModel.fit1 = this.record1[1].points;  //Fit points
        this.loginModel.unfit = this.record1[2].reps;  //unfit reps
        this.loginModel.unfit1 = this.record1[2].points;  //unfit points
        this.sucMsg = true;
      } else {
        this.errMsg = false;
        this.router.navigate(['/user']);
      }
    })
  }

}

