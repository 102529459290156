import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newtest',
  templateUrl: './newtest.component.html',
  styleUrls: ['./newtest.component.scss']
})
export class NewtestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
