<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/show-wearing']">Show Wearing List</a></li>
                            <li class="breadcrumb-item active">Add Wearing Types</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Add Wearing list</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <form class="form-horizontal m-t-20" action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Add Wearing Type</label>
                                        <input type="text" [(ngModel)]="loginModel.type" name="type"
                                            class="form-control" required>
                                        <span class="err_msg" *ngIf="addValidationError.type">{{addValidationError.type}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="sub_btn">
                                <!-- <button class="btn btn-primary" type="button">Add</button> -->
                                <button class="btn btn-primary" (click)="add_wearing()" type="button">Add Wearing
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>