<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/application-setting']">Application Settings</a></li>
                            <li class="breadcrumb-item active">Wearing Type List</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Wearing Type List</h4>
                </div>
            </div>
        </div>

        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header application-header">
                        <div class="search_bar d-sm-flex">
                            <form class="m-0">
                                <input type="text" class="form-control" [(ngModel)]="loginModel.searchKey"
                                    name="searchKey" placeholder="Search...">
                                <button class="btn btn-primary mt-4" (click)="view_wearing_type()">Search</button>
                            </form>
                            <a [routerLink]="['/add-wearing']" class="head-btn">Add <i
                                    class="fas fa-plus-circle"></i></a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <div class="table-box">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th><div class="t_respons_sno">S.no</div></th>
                                        <th><div class="respons_type">Wearing Type</div></th>
                                        <th><div class="respons_tatus">Status</div></th>
                                        <th><div class="respons_action">Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div class="loader_area">
                                        <span *ngIf="loaderShow">
                                            <app-loader class="table_loader"></app-loader>
                                        </span>
                                    </div>
                                    <tr *ngFor="let obj of record; let i = index ">
                                        <td>{{ (currentPage-1)*10+i+1 }}</td>
                                        <td *ngIf="obj.type!=null">{{obj.type}}</td>
                                        <td *ngIf="obj.type==null">NaN</td>


                                        <td class="active" (click)="change_status(obj.id,obj.status)"
                                            *ngIf="obj.status=='1'"><span>Active</span></td>
                                        <td class="de_active" (click)="change_status(obj.id,obj.status)"
                                            *ngIf="obj.status=='0'"><span>De-Active</span></td>
                                        <td>
                                            <div class="btn_area">
                                                <a routerLink="/edit-wearing/{{obj.id}}"><i class="fas fa-edit"></i></a>
                                                <!-- <a routerLink="/detailed-view-exercise/{{obj.id}}"><i class="fas fa-eye"></i></a> -->
                                                <a (click)="exercise_delete(obj.id,obj.renew_exercise)"><i
                                                        class="fas fa-trash-alt"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="recordcheck">
                                        <td colspan="7" class="text-center" style="color:red;">
                                            No record Found !
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <nav aria-label="Page navigation" class="mt-3">
                                <ul class="pagination">
                                    <li class="page-item disabled_back" *ngIf="currentPage==1"><a
                                            class="page-link">Previous</a>
                                    <li class="page-item" *ngIf="currentPage!=1" (click)="pageChange(currentPage-1)"><a
                                            class="page-link">Previous</a>
                                    </li>
                                    <li class="page-item" *ngFor='let in of counter(totalPages) ;let m = index'>
                                        <a class="page-link active" *ngIf="((m+1)==currentPage )">{{m+1}}</a>
                                        <a class="page-link"
                                            *ngIf="(m+1)!==currentPage && (m+1)>=(currentPage-2) && (m+1)<=(currentPage+2)"
                                            (click)="pageChange(m+1)">{{m+1}}</a>
                                    </li>

                                    <li class="page-item disabled_back" *ngIf="totalPages==currentPage">
                                        <a class="page-link">Next</a></li>
                                    <li class="page-item" *ngIf="totalPages!==currentPage"
                                        (click)="pageChange(currentPage+1)">
                                        <a class="page-link">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>