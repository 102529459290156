import { Component, OnInit } from '@angular/core';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }
  sucMsg:string=null;
  errMsg:string=null; 
  successMessage='Record updated Successfully'; 
  record:any=[];
  records:any=[];

  recovery:any=[];
  productivity:any=[];
  renewal:any=[];
  wearing:any=[];
  coupon:any=[];
  
  total : number= 0;

  ngOnInit(): void {
    this.show_counters();  
    this.show_enquiry_counters();
    this.active_recovery_counts();
    this.productivity_counts();
    this.renewal_period_count();
    this.view_wearing_count();
    this.view_coupon_count();
    this.isLoggedIn();
  };

  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }


//  conponent to show  All User counter
    show_counters(){
  this.accountService.user_counts().then((response)=>{
    if(response.code==201){
      this.record = response.result;
      this.router.navigate(['/dashboard']);
    }else{  
      this.errMsg = 'No Records';
      this.router.navigate(['/dashboard']);
    }
  }) 
    }
//  conponent to show  User Enquiry counter
    show_enquiry_counters(){
      this.accountService.user_Showcounts().then((response)=>{
        if(response.code==201){
          this.records = response.result;
          this.router.navigate(['/dashboard']);
        }else{  
          this.router.navigate(['/dashboard']);
        }
      }) 
    }
//Active Recovery Counts
    active_recovery_counts(){
      this.accountService.recovery_count().then((response)=>{
        if(response.code==201){
          this.recovery = response.result;
          this.router.navigate(['/dashboard']);
        }else{  
          this.router.navigate(['/dashboard']);
        }
      }) 
    }
    //Active Recovery Counts
    productivity_counts(){
      this.accountService.productivities_count().then((response)=>{
        if(response.code==201){
          this.productivity = response.result;
          this.router.navigate(['/dashboard']);
        }else{  
          this.router.navigate(['/dashboard']);
        }
      }) 
    }
    //Renewal Period Counts
    renewal_period_count(){
      this.accountService.renewal_period_count().then((response)=>{
        if(response.code==201){
          this.renewal = response.result;
          this.router.navigate(['/dashboard']);
        }else{  
          this.router.navigate(['/dashboard']);
        }
      }) 
    }
    //Renewal Period Counts
    view_wearing_count(){
      this.accountService.view_wearing_count().then((response)=>{
        if(response.code==201){
          this.wearing = response.result;
          this.router.navigate(['/dashboard']);
        }else{  
          this.router.navigate(['/dashboard']);
        }
      }) 
    }
    
      //Coupon Counts
      view_coupon_count(){
        this.accountService.view_coupon_count().then((response)=>{
          if(response.code==201){
            this.coupon = response.result;
            this.router.navigate(['/dashboard']);
          }else{  
            this.router.navigate(['/dashboard']);
          }
        }) 
      }
    

  // show_counters(){
  //   this.accountService.user_counts().then((response)=>{
  //     //console.log(response);
  //     if(response.code==201){
  //       this.record = response.result.count;
  //       //console.log(this.record);
  //       this.sucMsg = 'success';
  //       setTimeout (() => { this.sucMsg = '';  }, 3000);
  //       this.router.navigate(['/dashboard']);
  //     }else{  
  //       this.errMsg = 'No Records';
  //       setTimeout (() => { this.errMsg = '';  }, 3000);
  //       this.router.navigate(['/dashboard']);
  //     }
  //   }) 
  //  }
}

