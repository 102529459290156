import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-productivity-listing',
  templateUrl: './productivity-listing.component.html',
  styleUrls: ['./productivity-listing.component.scss']
})

export class ProductivityListingComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong';
  record:any=[];
  records:any=[];
  public getId: any = [];
  total : number= 0;
  totalPages : number= 0;
  currentPage:number=1;
  page : number = 1;
  key: string = 'recommend'; //set default
  reverse: boolean = false;
  loaderShow: boolean = false;
  searchKey: '';
  recordcheck:boolean = false;
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      page:1,
		  sortKey: '',
    }
    this.view_productivity();
    this.isLoggedIn();
  }

  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }
  view_productivity()
  {
    this.loaderShow = true;
    this.loginModel.page = this.currentPage;
    this.accountService.view_prod(this.loginModel).then((response)=>{
      if(response.code==201){
        this.record=response.result.rows;
        this.total = response.result.count;
         this.totalPages=Math.ceil( response.result.count/10);
         this.loaderShow = false;
         this.recordcheck=false;
         if(this.total == 0){
          this.recordcheck=true;
          this.record = [];
         } 
      }else if(response.code ==401){
        this.loaderShow = false;
        this.record = [];
        this.recordcheck=true;
        //this.router.navigate(['/user']);
      }
    })
  }  

  pageChange(currentPage){
    this.currentPage=currentPage;
    if(this.loginModel.searchKey==""){
      this.view_productivity();
    }else{
      this.view_productivity();
    } 
  }

  counter(i: number) {
    return new Array(i);
  }

  exercise_delete(id,productivity_exercise){
    if(confirm("Are you sure to delete !")) {
      var valid=true;
     this.accountService.deleteProductivityExercise(id).then((response)=>{
      this.loaderShow = false;
      if(response.code==200){
        this.sucMsg = true;
              this.successMessage='Productivity Deleted Successfully';
              this.view_productivity();
              setTimeout (() => {  this.sucMsg = false }, 1500);
      }else{
        this.errMsg = true;
        this.router.navigate(['/']);
      }
      })
   }
  }

  change_status(id,status){
        var valid=true;
        this.loaderShow = true;
        this.accountService.change_statusProductivityExercise(id,status).then((response)=>{
          if(status==1){
            this.successMessage='Productivity Exercise Disabled Successfully';
          }else{
            this.successMessage='Productivity Exercise Enabled Successfully';
          }
          if(response.status==true){
            this.sucMsg = true;
            this.successMessage;
            this.view_productivity();
            setTimeout (() => {  this.sucMsg = false }, 1500);   
          }else{
            this.errMsg = true;
            this.router.navigate(['/']);
          }
          })
        
      }

      get_focus_tips() {
        this.accountService.get_focus_tip(this.loginModel).then((response) => {
          if (response.status == true) {
            this.record = response.result.rows;
          } else {
            this.successMessage='No Reocords Found';
            //this.router.navigate(['/user']);
          }
        })
      }


 

}
