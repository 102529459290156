import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { AccountService } from '../../services/account.service';
import { Headers, Http } from '@angular/http';
import { Cookie } from 'ng2-cookies';
import { Login_model } from '../../models/login_model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }
  sucMsg:boolean=false;
  errMsg:string='';
  ngOnInit(): void {
    this.loginModel=<Login_model>{
      email: '',
      password:'',
    }
    this.addValidationError = <Login_model>{
      email: '',
      password:'',
    }
  }

  loginAdmin(){
    var valid=true;
    this.addValidationError.email='';
    this.addValidationError.password='';
    if(this.loginModel.email==""){
      this.addValidationError.email='Please Enter Email';
      valid=false; 
    }
    if(this.loginModel.password==""){
      this.addValidationError.password='Please Enter Password';
      valid=false; 
    }
    if(valid){
      this.accountService.adminLogin(this.loginModel).then((response)=>{
		  console.log(response);
        if(response.code==200){
          this.sucMsg = true;
          localStorage.setItem('email', response.email);               
          this.router.navigate(['/dashboard']);
        }else{
          this.errMsg = 'Invalid Credential';
          this.router.navigate(['/']);
        }
      })
    }
    }

}
