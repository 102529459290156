<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">Manage Coupon </li>
                        </ol>
                    </div>
                    <h4 class="page-title">Manage Coupon</h4>

                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div class="search_bar">
                            <form class="m-0">
                                <input type="text" class="form-control" [(ngModel)]="loginModel.searchKey"
                                    name="searchKey" placeholder="Search...">
                                <button class="btn btn-primary mt-4" (click)="search_coupon()">Search</button>
                            </form>
                        
                        </div>
                        <a  class="head-btn" data-toggle="modal" data-target="#add-coupon"><i  class="fas fa-plus mr-2"></i>Add Coupon </a>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ sucMsg }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <div class="table-box">
                            <table class="table table-hover">
                                <div class="loader_area">
                                    <span *ngIf="loaderShow">
                                        <app-loader class="table_loader"></app-loader>
                                    </span>
                                </div>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="t_respons_sno">S.no</div>
                                        </th>
                                        <th class="sort_menu">
                                            <div class="t_respons_name">
                                              Title
                                            </div>
                                        </th>
                                        <th>
                                            <div class="t_validTo">Coupon Logo</div>
                                        </th>
                                        
                                        <th class="sort_menu">
                                            <div class="t_validfrom">Valid From</div>
                                        </th>
                                        <th>
                                            <div class="t_validTo">Valid To</div>
                                        </th>

                                        <th>
                                            <div class="t_validTo">Tags</div>
                                        </th>

                                        <th>
                                            <div class="t_dis_type">Discount Type</div>
                                        </th>
                                        <th>
                                            <div class="t_dis_value">Discount Value</div>
                                        </th>
                                        <th>
                                            <div class="t_dis_value">Coupon Status</div>
                                        </th>
                                        <th style="width:30%">
                                            <div class="t_dis_value">Description</div>
                                        </th>
                                        <!-- <th>
                                            <div class="t_respons_action">Status</div>
                                        </th> -->
                                        <th>
                                            <div class="t_respons_action">Action</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of record; let i = index ">
                                        <td>{{ (currentPage-1)*10+i+1 }}</td>
                                        <td *ngIf="obj.title!=null">{{obj.title}}</td>
                                        <td *ngIf="obj.title==null">NaN</td>
                                        <td *ngIf="obj.coupon_logo!=null">
                                        <img src="http://18.219.104.111/api/src/public/coupon_logo/{{obj.coupon_logo}}" alt="coupon" height="80" width="80"></td>
                                        <td *ngIf="obj.coupon_logo==null">NaN</td>
                                        
                                        
                                        <td *ngIf="obj.valid_from!=null">{{obj.valid_from}}</td>
                                        <td *ngIf="obj.valid_from==null">NaN</td>
                                       

                                        <td *ngIf="obj.valid_to!=null">{{obj.valid_to}}</td>
                                        <td *ngIf="obj.valid_to==null">NaN</td>
                                        <td *ngIf="obj.tags!=null">{{obj.tags}}</td>
                                        <td *ngIf="obj.tags==null">NaN</td>

                                        <td *ngIf="obj.discount_type!=null">{{obj.discount_type}}</td>
                                        <td *ngIf="obj.discount_type==null">NaN</td>

                                        <td *ngIf="obj.discount_value!=null">{{obj.discount_value}}</td>
                                        <td *ngIf="obj.discount_value==null">NaN</td>
                                         
                                        <td *ngIf="obj.coupon_status==1">Un-Used</td>
                                        <td *ngIf="obj.coupon_status==2">Used</td>

                                        <td *ngIf="obj.description!=null">{{obj.description}}</td>
                                        <td *ngIf="obj.description==null">NaN</td>


                                        <!-- <td class="active" (click)="change_status(obj.id,obj.status)"
                                            *ngIf="obj.status=='1'"><span>Active</span></td>
                                        <td class="de_active" (click)="change_status(obj.id,obj.status)"
                                            *ngIf="obj.status=='0'"><span>De-Active</span></td> -->
                                        
                                        <td>
                                            <div class="btn_area">
                                                <a (click)="coupon_update(obj.id)" data-toggle="modal" data-target="#edit-coupon"><i class="far fa-edit"></i></a>
                                                <a routerLink="/view-offer/{{obj.id}}"><i class="fas fa-eye"></i></a>
                                                <a (click)="coupon_delete(obj.id,obj.title)"><i
                                                    class="fas fa-trash-alt"></i></a>
                                            </div>
                                        </td>


                                    </tr>
                                    <tr *ngIf="recordcheck">
                                        <td colspan="7" class="text-center" style="color:red;">
                                            No record Found !
                                        </td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation" class="mt-3">
                                <ul class="pagination">
                                    <li class="page-item disabled_back" *ngIf="currentPage==1"><a
                                            class="page-link">Previous</a>
                                    <li class="page-item" *ngIf="currentPage!=1" (click)="pageChange(currentPage-1)"><a
                                            class="page-link">Previous</a>
                                    </li>
                                    <li class="page-item" *ngFor='let in of counter(totalPages) ;let m = index'>
                                        <a class="page-link active" *ngIf="((m+1)==currentPage )">{{m+1}}</a>
                                        <a class="page-link"
                                            *ngIf="(m+1)!==currentPage && (m+1)>=(currentPage-2) && (m+1)<=(currentPage+2)"
                                            (click)="pageChange(m+1)">{{m+1}}</a>
                                    </li>

                                    <li class="page-item disabled_back" *ngIf="totalPages==currentPage">
                                        <a class="page-link">Next</a></li>
                                    <li class="page-item" *ngIf="totalPages!==currentPage"
                                        (click)="pageChange(currentPage+1)">
                                        <a class="page-link">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- end container -->
</div>

<div class="modal" id="add-coupon" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Coupon</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form action="">
                
                <div class="form-group">
                    <label for="title">Title*</label>
                        <input type="text" [(ngModel)]="loginModel.title" name="title" class="form-control">
                        <span class="err_msg" *ngIf="addValidationError.title">{{addValidationError.title}}</span>
                </div>
                <div class="form-group">
                    <label for="name">Logo*</label>
                    <input type="file" class="form-control" [(ngModel)]="loginModel.part_image" id="part_image" name="part_image"  (change)="onFileSelect($event)" accept="image/*">

                <span class="err_msg" *ngIf="addValidationError.part_image_error">{{addValidationError.part_image_error}}</span>
                </div>
                <div class="form-group">
                    <label for="validfrom">Tags*</label>
                    <input type="text" [(ngModel)]="loginModel.tags" name="tags" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.tags">{{addValidationError.tags}}</span>
                </div>
                <div class="form-group">
                    <label for="validfrom">Valid From*</label>
                    <input type="date" [(ngModel)]="loginModel.valid_from" name="valid_from" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.valid_from">{{addValidationError.valid_from}}</span>
                </div>
                <div class="form-group">
                    <label for="validTo">Valid To*</label>
                    <input type="date" [(ngModel)]="loginModel.valid_to" name="valid_to" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.valid_to">{{addValidationError.valid_to}}</span>
                </div>
                <div class="form-group">
                    <label for="discount_type">Discount Type*</label>
                    <select class="form-control" id="discount_type" [(ngModel)]="loginModel.discount_type" name="discount_type">
                        <option value="Percent">Percent</option>
                        <option value="Fixed">Fixed</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
               <span class="err_msg" *ngIf="addValidationError.discount_type">{{addValidationError.discount_type}}</span>
                   
                </div>
                <div class="form-group">
                    <label for="discountValue">Discount Value*</label>
                    <input type="text" [(ngModel)]="loginModel.discount_value" name="discount_value" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.discount_value">{{addValidationError.discount_value}}</span>
                </div>
                 <div class="form-group">
                    <label for="description">Prize in Fitness Point*</label>
                      <input type="number" [(ngModel)]="loginModel.prize_in_fitness_point" name="prize_in_fitness_point" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.prize_in_fitness_point">{{addValidationError.prize_in_fitness_point}}</span>
                </div>
                <div class="form-group">
                    <label for="description"> Redeem Description*</label>
                    <textarea class="form-control" [(ngModel)]="loginModel.description" name="description" id="description"></textarea>
                    <span class="err_msg" *ngIf="addValidationError.description">{{addValidationError.description}}</span>
                </div>
                <div class="form-group">
                    <label for="validfrom">Redeem Product URL*</label>
                    <textarea class="form-control" placeholder="Add URL Here...." [(ngModel)]="loginModel.redeem_instructions" name="redeem_instructions" id="redeem_instructions"></textarea>
                    <span class="err_msg" *ngIf="addValidationError.redeem_instructions">{{addValidationError.valid_from}}</span>
                </div>
                <div class="form-group">
                    <label for="validTo">Term and Conditions(link)*</label>
                    <textarea class="form-control" [(ngModel)]="loginModel.terms_condition" name="terms_condition" id="terms_condition"></textarea>
                    <span class="err_msg" *ngIf="addValidationError.terms_condition">{{addValidationError.terms_condition}}</span>
                </div>
                <button type="submit" (click)="add_coupon()" class="btn btn-primary px-4 float-right">SUBMIT</button>
            </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="edit-coupon" tabindex="-1" aria-labelledby="editcouponlabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editcouponlabel">Edit Coupon</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

      

        <div class="modal-body">
            <form action="">
                
                <div class="form-group">
                    <label for="title">Title*</label>
                    <input type="text" [(ngModel)]="loginModel.title" name="title" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.title">{{addValidationError.title}}</span>
                </div>
                <div class="form-group">
                    <label for="name">Logo*</label>
                    <input type="file" class="form-control" [(ngModel)]="loginModel.coupon_logo" id="part_image" name="part_image"  (change)="onFileSelect($event)" accept="image/*">

                <span class="alert-msg" *ngIf="addValidationError.coupon_logo">{{addValidationError.coupon_logo}}</span>
                </div>
                <div class="form-group">
                    <label for="validfrom">Tags*</label>
                    <input type="text" [(ngModel)]="loginModel.tags" name="tags" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.tags">{{addValidationError.tags}}</span>
                </div>
                <div class="form-group">
                    <label for="validfrom">Valid From*</label>
                    <input type="date" [(ngModel)]="loginModel.valid_from" name="valid_from" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.valid_from">{{addValidationError.valid_from}}</span>
                </div>
                <div class="form-group">
                    <label for="validTo">Valid To*</label>
                    <input type="date" [(ngModel)]="loginModel.valid_to" name="valid_to" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.valid_to">{{addValidationError.valid_to}}</span>
                </div>
                <div class="form-group">
                    <label for="discount_type">Discount Type*</label>
                    <select class="form-control" id="discount_type" [(ngModel)]="loginModel.discount_type" name="discount_type">
                        <option value="Percent">Percent</option>
                        <option value="Fixed">Fixed</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
               <span class="err_msg" *ngIf="addValidationError.discount_type">{{addValidationError.discount_type}}</span>
                   
                </div>
                <div class="form-group">
                    <label for="discountValue">Discount Value*</label>
                    <input type="text" [(ngModel)]="loginModel.discount_value" name="discount_value" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.discount_value">{{addValidationError.discount_value}}</span>
                </div>
                 <div class="form-group">
                    <label for="description">Prize in Fitness Point*</label>
                      <input type="number" [(ngModel)]="loginModel.prize_in_fitness_point" name="prize_in_fitness_point" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.prize_in_fitness_point">{{addValidationError.prize_in_fitness_point}}</span>
                </div>
                <div class="form-group">
                    <label for="description"> Redeem Description*</label>
                    <textarea class="form-control" [(ngModel)]="loginModel.description" name="description" id="description"></textarea>
                    <span class="err_msg" *ngIf="addValidationError.description">{{addValidationError.description}}</span>
                </div>
                <div class="form-group">
                    <label for="validfrom">Redeem Product URL*</label>
                    <textarea class="form-control" placeholder="Add URL Here...." [(ngModel)]="loginModel.redeem_instructions" name="redeem_instructions" id="redeem_instructions"></textarea>
                    <span class="err_msg" *ngIf="addValidationError.redeem_instructions">{{addValidationError.valid_from}}</span>
                </div>
                <div class="form-group">
                    <label for="validTo">Term and Conditions(link)*</label>
                    <textarea class="form-control" [(ngModel)]="loginModel.terms_condition" name="terms_condition" id="terms_condition"></textarea>
                    <span class="err_msg" *ngIf="addValidationError.terms_condition">{{addValidationError.terms_condition}}</span>
                </div>
                <button type="submit" (click)="update_coupon()" class="btn btn-primary px-4 float-right">Update</button>
            </form>
        </div>
      </div>
    </div>
  </div>

<app-footer></app-footer>