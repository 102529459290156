import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model } from '../../models/login_model';
import { Validation_model } from '../../models/Validation_model';
import { AccountService } from '../../services/account.service';
import { FormBuilder, Validators } from "@angular/forms";


@Component({
  selector: 'app-add-productivity',
  templateUrl: './add-productivity.component.html',
  styleUrls: ['./add-productivity.component.scss']
})

export class AddProductivityComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record Added successfully'; 
  errorMessage='Something went wrong'; 
  recordS: any = [];
  constructor( public fb:FormBuilder, private accountService: AccountService, private route: ActivatedRoute, private router: Router,  private http: Http) { }

  ngOnInit(): void {
    this.loginModel = <Login_model>{
      productivity_exercise: '',
      quotes: '',
      description: '',
      productivity_interruption_id:''
    }
    this.addValidationError = <Validation_model>{
      productivity_exercise: '',

      quotes: '',
    }
    this.getId = this.route.params.subscribe(params => {
      this.getId = params['id'];
    });
    this.isLoggedIn();
   this.get_focus_tips();
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if (this.loginModel.email == null) {
      this.router.navigate(['/']);
    }
  }
  onValueChange(id){
    this.loginModel.productivity_interruption_id=id; //Interruption table ID //st_focus_tips id
    this.loginModel.productivity_exercise='N/A'; //This is nothing just blank string
  }

  // getValue(interruption_name){
  // alert(interruption_name);
  // }

  add_productivity() {
    var valid = true;
    this.addValidationError.productivity_exercise = '';
    this.addValidationError.quotes = '';
    if (this.loginModel.productivity_exercise == "") {
      this.addValidationError.productivity_exercise = 'Please Select Interruption Type';
      valid = false;
    }
    if (this.loginModel.quotes == "") {
      this.addValidationError.quotes = 'Please Enter Quotes';
      valid = false;
    }
    if (valid) {
      this.accountService.add_prod(this.loginModel).then((response) => {
        if (response.status == true) {
          this.sucMsg = true;
          setTimeout (() => { this.router.navigate(['/productivity-listing']);  }, 1500);
        } else {
          this.errMsg = true;
          this.router.navigate(['/']);
        }
      })
    }
  }
  get_focus_tips() {
    this.accountService.get_focus_tip(this.loginModel).then((response) => {
     
      if (response.status == true) {
        this.recordS = response.result;
     
      } else {
        this.successMessage='No Record Found';
      }
    })
  }

  

  
}
