<!-- Begin page -->
<div class="accountbg"></div>
<div class="wrapper-page">

    <div class="card">
        <div class="card-body">
            <a href="#" class="account-logo"><img src="/assets/images/logo.svg" alt=""></a>
            <h3 class="text-center mt-0 m-b-15">
                Change Password
            </h3>
            <!-- <h4><span *ngIf="addValidationError.success">{{addValidationError.success}}</span>
             </h4> -->
            <div class="p-3">
                <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                  Your Password Has Been Changed Successfully!    
                </div>
                <form class="form-horizontal" action="">
                    <div class="form-group row">
                        <div class="col-12">
                            <input class="form-control" type="password" name="password"
                                [(ngModel)]="loginModel.password" required="" placeholder="New Password">
                            <span *ngIf="addValidationError.password">{{addValidationError.password}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <input class="form-control" type="password" name="c_password"
                                [(ngModel)]="loginModel.c_password" required="" placeholder="Confirm New Password">
                            <span *ngIf="addValidationError.c_password">{{addValidationError.c_password}}</span>

                        </div>
                    </div>

                    <div class="form-group text-center row m-t-20">
                        <div class="col-12 pos_relate">
                            <button class="login_btn" style="background-color: #fb7e21;" (click)="update_recover_password()" type="button">Update</button>
                            <span class="errMsg" *ngIf="errMsg">{{ errMsg }}</span>
                        </div>
                    </div>
                    <div class="form-group m-t-10 mb-0 row">
                        <div class="col-sm-7 m-t-10">
                            <a href routerLink="/" class="text-muted"><i class="mdi mdi-lock"></i>
                                <small style="font-size: 13px;">Login</small></a>
                        </div>

                    </div>


                </form>
            </div>
        </div>
    </div>
</div>