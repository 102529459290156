import { Injectable, Input } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment';
import { Login_model } from '../models/login_model';
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private adminLoginUrl =  "/api/adminLogin";
  private forgotUrl =  "/api/forgot";
  private usersUrl =  "/api/users";
  private sort_user =  "/api/sort_user_showAll";
  private users_enquiryUrl =  "/api/showAll_enquiry";
  private deleteEnquiryUser =  "/api/deleteEnquiryUser";
  private userStatusEnquiryUser =  "/api/change_statusEnquiryUser";
  private get_equipments =  "/api/get_equipments";



  private policyUrl =  "/api/save_policy";
  private showpolicyUrl =  "/api/show_policy";
  private About_usUrl =  "/api/about_us";
  private Show_about_us =  "/api/show_about_us";

  private user_listing =  "/api/fetch_single_user"; //workinggg

  private userStatusChangeUrl =  "/api/change_status";
  private getUserUrl =  "/api/fetch_single_user";
  private deleteUserUrl =  "/api/deleteUser";
  private updateUserUrl =  "/api/update_user";
  private updatePassUrl =  "/api/change_password";
  private updateEmailUrl =  "/api/change_email";


  //Dashboard
  private user_countUrl =  "/api/user_count";
  private Showenquirycounts =  "/api/showAll_enquiry_count";
  private recovery_countUrl =  "/api/showAll_Recovery_count";
  private productivity_countUrl =  "/api/showAll_productivity_count";
  private renewal_period_countUrl =  "/api/showAll_Renewal_count";
  private view_wearing_countUrl =  "/api/showAll_wearing_count";
  private view_coupon_countUrl =  "/api/showAll_coupon_count";


  private show_total_point_sum =  "/api/point_sum";
  private show_user_tasks =  "/api/show_task";
  private detailed_info =  "/api/show_user_detailed_info";
  private show_user_workstation_info =  "/api/show_user_workstation_info";
  private user_wearing_types =  "/api/user_wearing_types";

  private add_exercises =  "/api/add_exercise";
  private view_exercise =  "/api/showAllExercise";
  private delete_exercise =  "/api/deleteExercise";
  private exercise_status =  "/api/change_statusExercise";
  //Productivity
  private add_productivities =  "/api/add_productivity";
  private view_productivies =  "/api/showProductivityExercise";
  private deleteProductivityExercises =  "/api/deleteProductivityExercise";
  private change_statusProductivityExercises =  "/api/change_statusProductivityExercise";
  private getProductivityUrl =  "/api/getProductivityExercise";
  private updateProductivityUrl =  "/api/updateProductivityExercise";

  private getProductivityExercise =  "/api/getProductivityExercises";

  //renewal Period
  private add_renewalExercise =  "/api/add_renewal_period";
  private view_renewal_periods =  "/api/showRenewalExercise";
  private deleteRenewalExercises =  "/api/deleteRenewalExercise";
  private change_statusRenewalExercises =  "/api/change_statusRenewalExercise";
  private getRenewalUrl =  "/api/getRenewalUrl";
  private updateRenewalUrl =  "/api/updateRenewalUrl";

  // Wearing Types
  private add_wearing_types =  "/api/add_wearing_types";
  private showWearingTypes =  "/api/showWearingTypes";
  private deleteWearingTypes =  "/api/deleteWearingTypes";
  private change_statusWearingTypes =  "/api/change_statusWearingTypes";
  private getWearingUrl =  "/api/getWearingUrl";
  private updateWearingUrl =  "/api/updateWearingUrl";

  // Interruption Types
  private add_interruption_Url =  "/api/add_interruption_types";
  private showinterruptionTypes =  "/api/showinterruptionTypes";
  private deleteinterruptionTypes =  "/api/deleteinterruptionTypes";
  private change_statusinterruptionTypes =  "/api/change_statusinterruptionTypes";
  private getinterruptionUrl =  "/api/getinterruptionUrl";
  private updateinterruptionUrl =  "/api/updateinterruptionUrl";

  // Equipment Types
  private add_equipment_Url =  "/api/add_equipment_Url";
  private showequipmentTypes =  "/api/showequipmentTypes";
  private delete_equipmentTypes =  "/api/delete_equipmentTypes";
  private change_statusEquipmentTypes =  "/api/change_statusEquipmentTypes";
  private getequipmentUrl =  "/api/getequipmentUrl";
  private update_equipmentUrl =  "/api/update_equipmentUrl";


  //recovered password
  private update_recover_pwd =  "/api/recover_password";
  //Get focus tips
  private get_focus_tips =  "/api/get_focus_tips";
  //user detailed view
  private user_detailed_view =  "/api/get_detailed_views";
  private get_detailed_equipment =  "/api/get_detailed_equipment";
  private get_fitness_levels =  "/api/get_fitness_level";

  //update case
  private get_exercise_based_equipment_list =  "/api/get_exercise_based_equipment_list";
  //update Exercise
  private update_exerciseUrl =  "/api/update_exercise";
  private interup_feedback =  "/api/user_interruption_listing";
  private varify_email_url =  "/api/activate";
  private add_pointsUrl =  "/api/additional_points_admin";
  private add_couponsType =  "/api/save_coupon";
  private update_couponsType =  "/api/updateCoupon";

  private allCoupons =  "/api/ShowallCoupon";
  private deleteCouponTypes =  "/api/deleteCoupon";
  private coupon_detailsTypes =  "/api/view_coupon";
  private add_codeTypes =  "/api/add_code";
  private ShowallCode =  "/api/ShowallCode";
  private ShowallBonus =  "/api/ShowallBonus";
  private deleteCodeTypes =  "/api/deleteCode";
  private change_code_statusurl =  "/api/change_code_status";
  private update_code_detailUrl =  "/api/update_code";



  private admin_forget_password_url =  '/api/admin_forget_password';
  private admin_recover_password_url =  '/api/admin_recover_password_link';




  constructor(private baseHttpService: BaseHttpService) { }



  // For Dashboard count listing
  user_Showcounts(): Promise<any> {
    return this.baseHttpService.Get(this.Showenquirycounts,)
      .then(function (response) {
        return response.json();
      });
  }

  user_counts(): Promise<any> {
    return this.baseHttpService.Get(this.user_countUrl,)
      .then(function (response) {
        return response.json();
      });
  }

  recovery_count(): Promise<any> {
    return this.baseHttpService.Get(this.recovery_countUrl,)
      .then(function (response) {
        return response.json();
      });
  }
  productivities_count(): Promise<any> {
    return this.baseHttpService.Get(this.productivity_countUrl,)
      .then(function (response) {
        return response.json();
      });
  }
  renewal_period_count(): Promise<any> {
    return this.baseHttpService.Get(this.renewal_period_countUrl,)
      .then(function (response) {
        return response.json();
      });
  }
  view_wearing_count(): Promise<any> {
    return this.baseHttpService.Get(this.view_wearing_countUrl,)
      .then(function (response) {
        return response.json();
      });
  }
  view_coupon_count(): Promise<any> {
    return this.baseHttpService.Get(this.view_coupon_countUrl,)
      .then(function (response) {
        return response.json();
      });
  }



  //Dashboard count close

  //Exercise module
  add_exe(tempArr: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_exercises, tempArr)
      .then(function (response) {
        return response.json();
      });
  }

  view_exercises(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.view_exercise, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  delete_exercises(id): Promise<any> {
    return this.baseHttpService.Post(this.delete_exercise, { id })
      .then(function (response) {
        return response.json();
      });
  }

  exercises_status(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.exercise_status, { id, status })
      .then(function (response) {
        return response.json();
      });
  }

  get_equipment(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.get_equipments, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  //Exercise module Close



  //user full details
  view_users(id): Promise<any> {
    return this.baseHttpService.Post(this.user_listing, { id })
      .then(function (response) {
        return response.json();
      });
  }

  user_sort(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.sort_user, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  show_point_sum(user_id): Promise<any> {
    return this.baseHttpService.Post(this.show_total_point_sum, { user_id })
      .then(function (response) {
        return response.json();
      });
  }

  show_tasks(user_id): Promise<any> {
    return this.baseHttpService.Post(this.show_user_tasks, { user_id })
      .then(function (response) {
        return response.json();
      });
  }
  detailed_infos(user_id): Promise<any> {
    return this.baseHttpService.Post(this.detailed_info, { user_id })
      .then(function (response) {
        return response.json();
      });
  }

  show_user_workstation_infos(user_id): Promise<any> {
    return this.baseHttpService.Post(this.show_user_workstation_info, { user_id })
      .then(function (response) {
        return response.json();
      });
  }

  user_wearing_type(user_id): Promise<any> {
    return this.baseHttpService.Post(this.user_wearing_types, { user_id })
      .then(function (response) {
        return response.json();
      });
  }
  //user full details close


  adminLogin(loginModel: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.adminLoginUrl, loginModel)
      .then(function (response) {
        return response.json();
      });
  }

  forgot(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.forgotUrl, login_model)
      .then(function (response) {
        return response.json();
      });

  }



  // Privacy Policy Page API Calls
  show_policy(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.showpolicyUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  private_policy1(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.policyUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  // Privacy policy page api close


  // About us Page API calls

  show_about_us(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.Show_about_us, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  about_us(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.About_usUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  // About us page API calls close



  users(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.usersUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }


  users_enquiry(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.users_enquiryUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }


  update_user(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.updateUserUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }


  update_password(login_model): Promise<any> {
    let params = new HttpParams();
    let email = localStorage.getItem('email');
    let password = login_model.password;
    // debugger;
    console.log(email);
    return this.baseHttpService.Post(this.updatePassUrl, { email, password })
      .then(function (response) {
        return response.json();
      });

  }

  update_email(login_model): Promise<any> {
    let params = new HttpParams();
    let email = localStorage.getItem('email');
    let new_email = login_model.new_email;
    return this.baseHttpService.Post(this.updateEmailUrl, { email, new_email })
      .then(function (response) {
        return response.json();
      });

  }

  change_status(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.userStatusChangeUrl, { id, status })
      .then(function (response) {
        return response.json();
      });
  }


  StatusEnquiryUser(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.userStatusEnquiryUser, { id, status })
      .then(function (response) {
        return response.json();
      });
  }



  delete_user(user_id): Promise<any> {
    //let params = new HttpParams();
    return this.baseHttpService.Post(this.deleteUserUrl, { user_id })
      .then(function (response) {
        return response.json();
      });
  }

  deleteEnquiry(user_id): Promise<any> {
    //let params = new HttpParams();
    return this.baseHttpService.Post(this.deleteEnquiryUser, { user_id })
      .then(function (response) {
        return response.json();
      });
  }
  getUsers(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.getUserUrl, { id })
      .then(function (response) {
        return response.json();
      });
  }

  // Productivity Module
  add_prod(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_productivities, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  view_prod(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.view_productivies, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  deleteProductivityExercise(id): Promise<any> {
    return this.baseHttpService.Post(this.deleteProductivityExercises, { id })
      .then(function (response) {
        return response.json();
      });
  }
  change_statusProductivityExercise(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.change_statusProductivityExercises, { id, status })
      .then(function (response) {
        return response.json();
      });
  }
  getProductivity(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.getProductivityUrl, { id })
      .then(function (response) {
        return response.json();
      });
  }
  update_productivity(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.updateProductivityUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  //Productivity Module Close
  getProductivityExercisess(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.getProductivityExercise, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  // getProductivityExercisess(login_model:Login_model):Promise<any>{
  //   return this.baseHttpService.Post(this.getProductivityExercise,login_model)
  //   .then(function(response){
  //       return response.json();
  //   });
  // }


  // Renewal Module
  add_renew(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_renewalExercise, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  view_renewal(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.view_renewal_periods, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  deleteRenewalExercise(id): Promise<any> {
    return this.baseHttpService.Post(this.deleteRenewalExercises, { id })
      .then(function (response) {
        return response.json();
      });
  }
  change_statusRenewalExercise(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.change_statusRenewalExercises, { id, status })
      .then(function (response) {
        return response.json();
      });
  }
  getRenewal_period(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.getRenewalUrl, { id })
      .then(function (response) {
        return response.json();
      });
  }
  update_renewal_period(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.updateRenewalUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  // Wearing Module
  add_wearings(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_wearing_types, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  view_wearing(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.showWearingTypes, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  deletewearing(id): Promise<any> {
    return this.baseHttpService.Post(this.deleteWearingTypes, { id })
      .then(function (response) {
        return response.json();
      });
  }
  change_wearing(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.change_statusWearingTypes, { id, status })
      .then(function (response) {
        return response.json();
      });
  }
  getWearing(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.getWearingUrl, { id })
      .then(function (response) {
        return response.json();
      });
  }
  update_wearing(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.updateWearingUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }


  // Interruption Module
  add_interruption(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_interruption_Url, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  view_interruption(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.showinterruptionTypes, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  deleteinterruption(id): Promise<any> {
    return this.baseHttpService.Post(this.deleteinterruptionTypes, { id })
      .then(function (response) {
        return response.json();
      });
  }
  change_interruption(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.change_statusinterruptionTypes, { id, status })
      .then(function (response) {
        return response.json();
      });
  }
  getinterruption(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.getinterruptionUrl, { id })
      .then(function (response) {
        return response.json();
      });
  }
  update_interruption(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.updateinterruptionUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  // Equipment Module
  add_equipment(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_equipment_Url, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  view_equipment(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.showequipmentTypes, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  delete_equipment(id): Promise<any> {
    return this.baseHttpService.Post(this.delete_equipmentTypes, { id })
      .then(function (response) {
        return response.json();
      });
  }
  change_equipment(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.change_statusEquipmentTypes, { id, status })
      .then(function (response) {
        return response.json();
      });
  }
  getEquipment(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.getequipmentUrl, { id })
      .then(function (response) {
        return response.json();
      });
  }
  update_equipment_url(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.update_equipmentUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }




  //update recover password

  //Get focus Tips
  get_focus_tip(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.get_focus_tips, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  update_recover_password(token, password): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.update_recover_pwd, { token, password })
      .then(function (response) {
        return response.json();
      });
  }


  user_detailed_views(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.user_detailed_view, { id })
      .then(function (response) {
        return response.json();
      });
  }

  get_detailed_equipments(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.get_detailed_equipment, { id })
      .then(function (response) {
        return response.json();
      });
  }

  get_fitness_levelss(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.get_fitness_levels, { id })
      .then(function (response) {
        return response.json();
      });
  }

  get_fitness_level_view(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.get_fitness_levels, { id })
      .then(function (response) {
        return response.json();
      });
  }

  get_exercise_based_equipment_lists(id): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.get_exercise_based_equipment_list, { id })
      .then(function (response) {
        return response.json();
      });
  }

  update_exercises(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.update_exerciseUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  view_interruption_feedback(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.interup_feedback, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  varify_email(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.varify_email_url, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  add_points(login_model: Login_model): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.add_pointsUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  // coupon 
  // add_coupons(login_model:Login_model): Promise<any>{
  //   return this.baseHttpService.Post(this.add_couponsType,login_model)
  //   .then(function(response){
  //       return response.json();
  //   });
  // }

  add_coupons(formdata): Promise<any> {
    return this.baseHttpService.FormPost(this.add_couponsType, formdata)
      .then(function (response) {
        return response.json();
      });
  }
  update_coupons(formdata): Promise<any> {
    return this.baseHttpService.FormPost(this.update_couponsType, formdata)
      .then(function (response) {
        return response.json();
      });
  }


  allCoupon(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.allCoupons, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  delete_coupon(id): Promise<any> {
    return this.baseHttpService.Post(this.deleteCouponTypes, { id })
      .then(function (response) {
        return response.json();
      });
  }


  coupon_details(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.coupon_detailsTypes, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  //Add coupon
  add_code(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.add_codeTypes, login_model)
      .then(function (response) {
        return response.json();
      });
  }

  getallCode(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.ShowallCode, login_model)
      .then(function (response) {
        return response.json();
      });
  }
  getallBonus(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.ShowallBonus, login_model)
      .then(function (response) {
        return response.json();
      });
  }



  delete_code(id): Promise<any> {
    return this.baseHttpService.Post(this.deleteCodeTypes, { id })
      .then(function (response) {
        return response.json();
      });
  }

  change_code_status(id, status): Promise<any> {
    let params = new HttpParams();
    return this.baseHttpService.Post(this.change_code_statusurl, { id, status })
      .then(function (response) {
        return response.json();
      });
  }

  update_code_detail(login_model: Login_model): Promise<any> {
    return this.baseHttpService.Post(this.update_code_detailUrl, login_model)
      .then(function (response) {
        return response.json();
      });
  }



  admin_recover_password_link(email): Promise<any> {
    return this.baseHttpService.Post(this.admin_recover_password_url, { email })
      .then(function (response) {
        return response.json();
      });
  }

  admin_forget_password(token, password): Promise<any> {
    return this.baseHttpService.Post(this.admin_forget_password_url, { token, password })
      .then(function (response) {
        return response.json();
      });
  }


   
}
