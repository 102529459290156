import { Component, OnInit } from '@angular/core';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import{ AccountService} from'../../services/account.service';
@Component({
  selector: 'app-edit-wearing',
  templateUrl: './edit-wearing.component.html',
  styleUrls: ['./edit-wearing.component.scss']
})

export class EditWearingComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 loaderShow: boolean = false
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      type: '',
    }
    this.addValidationError = <Login_model>{
      type: '',
      }
     this.route.params.subscribe(params => {
       this.getId = params['id'];
     });
     this.getWearing();
     this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

  getWearing() {
    this.loaderShow=true;
    this.accountService.getWearing(this.getId).then((response) => {
        if(response.code==201){
         this.record=response.result;
         this.loginModel.type=this.record.type;
         this.loginModel.id=this.record.id;
         this.loaderShow=false;
        
        }else{
          this.errMsg;
          //this.router.navigate(['/user']);
        }
      })
  }
 
  update_wearing(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.type	='';
    if(this.loginModel.type==""){
      this.addValidationError.type='Please Enter Wearing Type';
      valid=false; 
    }
    if(valid){ 
      this.accountService.update_wearing(this.loginModel).then((response)=>{
        if (response.code == 200) {
          this.sucMsg=true;
          setTimeout (() => {  
           this.sucMsg = false; 
            this.router.navigate(['/show-wearing']); 
           }, 2000);
      } else {
          this.errMsg = true;
          this.errorMessage;
              setTimeout (() => {
                 this.sucMsg = false;
            this.router.navigate(['/show-wearing']); 
          }, 2000);
      }
      })
    }
    }
}



