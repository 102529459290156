import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Http, Headers } from '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Login_model;
  private headers = new Headers();
  EmailFrom: FormGroup
  isSubmitted: boolean = false;
  constructor(private accountService: AccountService, private route: ActivatedRoute, private router: Router, private http: Http
    , private _fb: FormBuilder
  ) {

    this.EmailFrom = this._fb.group({
      email: ["", [Validators.required, Validators.email]]
    })

  }
  sucMsg: boolean = false;
  // errMsg: string = '';
  errMsg:boolean = false;
  isAdmin: boolean = false;
  ngOnInit(): void {
    this.loginModel = <Login_model>{
      email: '',
    }
    this.addValidationError = <Login_model>{
      email: '',
    }



    this.route.queryParams.subscribe((params) => {
      const isAdmin = params['isAdmin'];
      if (isAdmin === '1') {
        this.isAdmin = true;
      }
    })
  }



  forgot() {
    var valid = true;
    this.addValidationError.email = '';
    // if (this.loginModel.email == "") {
    //   this.addValidationError.email = 'Please Enter Email';
    //   valid = false;
    // }

    if (!this.EmailFrom.valid) {
      this.isSubmitted = true;
      return;
    }

    if (valid) {

      this.accountService.admin_recover_password_link(this.EmailFrom.value.email).then((response) => {
        this.EmailFrom.reset();
        if (response.status) {
          this.sucMsg = true;
          this.errMsg = false;
          this.isSubmitted = false
          // this.router.navigate(['/dashboard']);
        } else {
          this.errMsg = true;
          this.sucMsg = false;
          this.isSubmitted = false
          // this.router.navigate(['/']);
        }
      })

      // if (this.isAdmin) {
      //   this.accountService.admin_recover_password_link(this.EmailFrom.value.email).then((response) => {
      //     if (response.code == 200) {
      //       this.sucMsg = true;
      //       this.router.navigate(['/dashboard']);
      //     } else {
      //       this.errMsg = 'Wrong Credentials';
      //       this.router.navigate(['/']);
      //     }
      //   })
      // } else {
      //   this.accountService.forgot(this.EmailFrom.value.email).then((response) => {
      //     if (response.success) {
      //       this.sucMsg = true;

      //       // this.router.navigate(['/dashboard']);
      //     } else {
      //       this.errMsg = 'Wrong Credentials';
      //       this.router.navigate(['/']);
      //     }
      //   })
      // }

    }
  }

}
