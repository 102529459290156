<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/productivity-listing']">Application Settings</a></li>
                            <li class="breadcrumb-item active">Update Productivity Exercise</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Update Productivity</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
          
            <div class="col-md-12">
                  <!-- Success msg html -->
            <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                {{ successMessage }}
            </div>
            <!-- Error msg html -->
            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                {{ errorMessage }}
            </div>
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal m-t-20" action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Interruption Type</label>
                                        <select id="productivity_exercise" name="productivity_exercise"
                                            class="form-control" (change)="onValueChange($event.target.value)">
                        <option *ngFor="let order of recordS; " value="{{order.id}}" [selected]="order.id == loginModel.productivity_interruption_id">
                      {{ order.focus_tip }}
                        </option>
                                        </select>
                                    </div>
                                    <input type="hidden" [(ngModel)]="loginModel.productivity_interruption_name" name="productivity_interruption_name" required=""
                                    class="form-control">
                                    <!-- <div class="form-group">
                                        <label>Update Interruption</label>
                                        <input type="text" [(ngModel)]="loginModel.productivity_exercise"
                                            name="productivity_exercise" class="form-control" required>
                                        <span
                                            *ngIf="addValidationError.productivity_exercise">{{addValidationError.productivity_exercise}}
                                        </span>
                                    </div> -->

                                    <label>Focus tip (Quotes)</label>
                                    <div class="form-group">
                                        <textarea name="quotes" [(ngModel)]="loginModel.quotes"
                                            ng-model="myTextarea"></textarea>

                                        <span class="err_msg" *ngIf="addValidationError.quotes">{{addValidationError.quotes}}</span>

                                        <input type="hidden" [(ngModel)]="loginModel.id" name="id" required=""
                                            class="form-control">

                                    </div>
                                    <label>Description(optional)</label>
                                    <div class="form-group">
                                        <textarea name="description" [(ngModel)]="loginModel.description"
                                        ng-model="myTextarea"></textarea>
                                    </div>
                                </div>

                            </div>

                            <div class="sub_btn">
                                <!-- <button class="btn btn-primary" type="button">Add</button> -->
                                <button class="btn btn-primary" (click)="update_productivity()" type="button">Update
                                    Productivity</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>