<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">Admin Profile</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Admin Profile</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <form>
                    <div class="card">
                        <div class="card-header">
                            <h4>Change Email</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <h6>Enter New Email:</h6>
                                    <input type="email" [(ngModel)]="loginModel.new_email" name="new_email" required=""
                                        class="form-control">
                                    <span class="err_msg" *ngIf="addValidationError.new_email">{{addValidationError.new_email}}</span>
                                </div>
                            </div>
                            <button (click)="update_email()" class="btn btn-primary mt-4">Update Email</button>
                            <div class="alert alert-success" role="alert" *ngIf="sucMsg1">
                                {{ successMessage1 }}
                                </div>
                                <div class="alert alert-danger" role="alert" *ngIf="errMsg1">
                                {{ errorMessage1 }}
                                </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form>
                    <div class="card mt-3">
                        <div class="card-header">
                            <h4>Change Password</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <h6>Enter New Password :</h6>
                                    <input type="password" [(ngModel)]="loginModel.password" name="password" required=""
                                        class="form-control">
                                    <span class="err_msg" *ngIf="addValidationError.password">{{addValidationError.password}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h6>Confirm Password :</h6>
                                    <input type="password" name="c_password" [(ngModel)]="loginModel.c_password" required=""
                                        class="form-control">
                                    <span class="err_msg" *ngIf="addValidationError.c_password">{{addValidationError.c_password}}</span>
                                </div>
                            </div>


                            <button (click)="update_password()" class="btn btn-primary mt-4">Update Password</button>

                            <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                                {{ successMessage }}
                                </div>
                                <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                                {{ errorMessage }}
                                </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> <!-- end container -->
</div>
<app-footer></app-footer>