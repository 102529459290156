<!-- Begin page -->
<div class="accountbg"></div>
<div class="wrapper-page">

    <div class="card">
        <div class="card-body">
            <a href="#" class="account-logo"><img src="assets/images/logo.svg" alt=""></a>
            <h3 class="text-center mt-0 m-b-15">
                Admin Login
            </h3>
            <div class="p-3">
                <form class="form-horizontal" action="">
                    <div class="form-group row">
                        <div class="col-12">
                            <input class="form-control" type="text" name="email" [(ngModel)]="loginModel.email"
                                required="" placeholder="Email">
                            <span class="err_msg" *ngIf="addValidationError.email">{{addValidationError.email}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <input class="form-control" type="password" name="password"
                                [(ngModel)]="loginModel.password" required="" placeholder="Password">
                            <span class="err_msg"
                                *ngIf="addValidationError.password">{{addValidationError.password}}</span>

                        </div>
                    </div>

                    <div class="form-group text-center row m-t-20">
                        <div class="col-12 pos_relate">
                            <button class="login_btn" (click)="loginAdmin()" type="button">Log In</button>
                            <span class="err_msg" class="errMsg" *ngIf="errMsg">{{ errMsg }}</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div >
                            <a href routerLink="/forget" class="text-muted">
                                <small style="font-size: 13px;">Forget Password ?</small></a>
                        </div>

                    </div>

                </form>
               
            </div>

        </div>
    </div>
</div>