import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import{ AccountService} from'../../services/account.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-edit-productivity-listing',
  templateUrl: './edit-productivity-listing.component.html',
  styleUrls: ['./edit-productivity-listing.component.scss']
})

export class EditProductivityListingComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 recordS:any=[];
 loaderShow: boolean = false
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      description: '',
      productivity_interruption_id:'',
      productivity_interruption_name:'',
      quotes :'',
      id :'',
    }
    this.addValidationError = <Login_model>{
      productivity_exercise: '',
      quotes :'',
      }
     this.route.params.subscribe(params => {
       this.getId = params['id'];
      //  this.getUser()
     });
     this.get_all_interruptions();
     this.getproductivity();
     this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

    onValueChange(id){
      debugger;
      this.loginModel.productivity_interruption_id=id; //Interruption table ID //st_focus_tips id
    //  this.loginModel.productivity_interruption_id=id; //Interruption table ID //st_focus_tips id
    }

    get_all_interruptions(){
      //alert('You');
     this.accountService.getProductivityExercisess(this.loginModel).then((response) => {
       console.log(response);
      if(response.code==201){
       this.recordS=response.result.rows;
      }else{
        this.sucMsg = false; 
      }
    });
  }

  getproductivity() {
    this.loaderShow=true;
    this.accountService.getProductivity(this.getId).then((response) => {
        if(response.code==201){
         this.record=response.result;
         this.loginModel.productivity_interruption_id=this.record.productivity_interruption_id;
         this.loginModel.quotes=this.record.quotes;
         this.loginModel.description=this.record.description;
         this.loginModel.id=this.record.id;
         this.loaderShow=false;
        }else{
        }
      })
  }

 
  update_productivity(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.quotes='';
    if(this.loginModel.quotes==""){
      this.addValidationError.quotes='Please Enter Quotes';
      valid=false; 
    }
    if(valid){
      this.accountService.update_productivity(this.loginModel).then((response)=>{
        if (response.code == 200) {
          this.sucMsg=true;
          setTimeout (() => {  
           this.sucMsg = false; 
            this.router.navigate(['/productivity-listing']); 
           }, 2000);
      } else {
          this.errMsg = true;
          this.errorMessage;
              setTimeout (() => {
                 this.sucMsg = false;
            this.router.navigate(['/productivity-listing']); 
          }, 2000);
      }
      })
    }
    }
}

