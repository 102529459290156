<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/view-exercise']">View Exercise List</a></li>
                            <li class="breadcrumb-item active">View Detailed Exercise</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Detailed View Exercise</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal m-t-20" action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Exercise</label>
                                        <p>{{ loginModel.exercise }}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Body Section</label>
                                        <p>{{ loginModel.body_section }}</p>
                                    </div>
                                    <div class="form-group">
                                        <label> Video Link</label>
                                        <p>{{ loginModel.video_link }}</p>
                                    </div>
                                    <label>Equipment Used</label>
                                       <p *ngFor="let obj of multi_record let i = index ">
                                        {{ i+1 }}.  {{obj.equipment_type}}  <i class="fa fa-check" aria-hidden="true"> </i>
                                        </p>
                                
                                </div>
                                <div class="col-md-6">
                                    <div class="table-responsive table_area">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th><div class="respons_level">Fitness Level</div></th>
                                                    <th><div class="respons_input">Reps</div></th>
                                                    <th><div class="respons_input">Points</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    <tr *ngFor="let obj of record; let i = index "> 
                                                        <td> {{obj.fit_level}}</td>
                                                        <td> {{obj.reps}}</td>
                                                        <td> {{obj.points}}</td>   
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
               
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>