import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { DataTablesModule } from 'angular-datatables';



@Component({
  selector: 'app-view-exercise',
  templateUrl: './view-exercise.component.html',
  styleUrls: ['./view-exercise.component.scss']
})
export class ViewExerciseComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record Added successfully'; 
  errorMessage='Something went wrong'; 
  record:any=[];
  records:any=[];
  public getId: any = [];
  total : number= 0;
  totalPages : number= 0;
  currentPage:number=1;
  page : number = 1;
  key: string = 'recommend'; //set default
  reverse: boolean = false;
  loaderShow: boolean = false;
  recordcheck:boolean = false;
  searchKey: '';
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      page:1,
		  sortKey: '',
    }
    this.view();
    this.isLoggedIn();
  }

  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

  view()
  {
    this.loaderShow = true;
    this.loginModel.page = this.currentPage;
    this.accountService.view_exercises(this.loginModel).then((response)=>{
      if(response.status==true){
        this.record=response.result.rows;
        this.total = response.result.count;
         this.totalPages=Math.ceil( response.result.count/10);
         this.loaderShow = false;
         if(this.total == 0){
          this.recordcheck=true;
         }     
      }else{
        this.loaderShow = false;
        this.recordcheck=true;
      }
    })
  }  

  

  pageChange(currentPage){
    this.currentPage=currentPage;
    if(this.loginModel.searchKey==""){
      this.view();
    }else{
      this.view();
    } 
  }

  counter(i: number) {
    return new Array(i);
  }


  exercise_delete(id,exercise){
    if(confirm("Are you sure to delete "+exercise)) {
     this.accountService.delete_exercises(id).then((response)=>{

      this.loaderShow = false;
      if(response.code==200){
        this.sucMsg = true;
              this.successMessage='Exercise Deleted Successfully';
              this.view();
              setTimeout (() => {  this.sucMsg = false }, 1500);
      }else{
        this.errMsg = true;
        this.router.navigate(['/']);
      }
      })
   }
  }

  change_status(id,status){
        var valid=true;
        this.loaderShow = true;
        this.accountService.exercises_status(id,status).then((response)=>{
          if(status==1){
            this.successMessage='Exercise Disabled Successfully';
          }else{
            this.successMessage='Exercise Enabled Successfully';
          }
          if(response.status==true){
            this.sucMsg = true;
            this.successMessage;
            this.view();
            setTimeout (() => {  this.sucMsg = false }, 2000);   
          }else{
            this.errMsg = true;
            this.router.navigate(['/']);
          }
          })
        
      }


 

}
