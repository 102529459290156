<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['#']">Africause</a></li>
                            <li class="breadcrumb-item active">Project List</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Project List</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Project List</h4>
                    </div>
                    <div class="card-body">
                        <div class="table-box">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>Name</th>
                                        <th>Donation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Abc Sharma</td>
                                        <td>50,000</td>
                                        <td>
                                            <a [routerLink]="['#']">Edit</a>
                                            <a [routerLink]="['#']">Delete</a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>2.</td>
                                        <td>xyz Sharma</td>
                                        <td>10,000</td>
                                        <td>
                                            <a [routerLink]="['#']">Edit</a>
                                            <a [routerLink]="['#']">Delete</a>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>