<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/view-exercise']">Application Settings</a></li>
                            <li class="breadcrumb-item active">Add Exercise</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Add Exercise</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <form class="form-horizontal m-t-20" action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Add Exercise</label>
                                        <input type="text" [(ngModel)]="loginModel.exercise" name="exercise"
                                            class="form-control" required>
                                           <span class="err_msg" *ngIf="addValidationError.exercise">{{addValidationError.exercise}}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Body Section</label>
                                        <input type="text" [(ngModel)]="loginModel.body_section" name="body_section"
                                            class="form-control" required>
                                           <span class="err_msg" *ngIf="addValidationError.body_section">{{addValidationError.body_section}}</span>
                                    </div>
                                    <div class="form-group">
                                        <label>Add Video Link</label>
                                        <input type="text" [(ngModel)]="loginModel.video_link" name="video_link"
                                            class="form-control" required>
                                           <span class="err_msg" *ngIf="addValidationError.video_link">{{addValidationError.video_link}}</span>
                                    </div>
                                    <label>Equipment</label>

                                    <div class="form-group">
                                        <div class="form-check" *ngFor="let cat of record;let j=index">
                                            <input class="form-check-input" name="{{ cat.equipment_type }}"
                                                type="checkbox" (change)="onChangeCategory(j,cat.id)">
                                            <label class="form-check-label" for="{{cat.equipment_type}}">
                                                {{cat.equipment_type}}
                                            </label>
                                        </div>
                                    </div>



                                </div>
                                <div class="col-md-6">
                                    <div class="table-responsive table_area">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th><div class="respons_level">Fitness Level</div></th>
                                                    <th><div class="respons_input">Reps</div></th>
                                                    <th><div class="respons_input">Points</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Super Fit</td>
                                                    <td><input type="number" [(ngModel)]="loginModel.super_fit"
                                                            name="super_fit" class="form-control">
                                                           <span class="err_msg" *ngIf="addValidationError.super_fit">{{addValidationError.super_fit}}</span>        
                                                    </td>
                                                    

                                                    <td><input type="number" [(ngModel)]="loginModel.super_fit1"
                                                            name="super_fit1" class="form-control">
                                                   <span class="err_msg" *ngIf="addValidationError.super_fit1">{{addValidationError.super_fit1}}</span>  
                                                   </td>    
                                                </tr>
                                                <tr>
                                                    <td>Fit</td>
                                                    <td><input type="number" [(ngModel)]="loginModel.fit" name="fit"
                                                            class="form-control">
                                                   <span class="err_msg" *ngIf="addValidationError.fit">{{addValidationError.fit}}</span>
                                                </td>

                                                    <td><input type="number" [(ngModel)]="loginModel.fit1" name="fit1"
                                                            class="form-control">
                                                   <span class="err_msg" *ngIf="addValidationError.fit1">{{addValidationError.fit1}}</span>
                                                   </td>
                                                </tr>
                                                <tr>
                                                    <td>Unfit</td>
                                                    <td><input type="number" [(ngModel)]="loginModel.unfit" name="unfit"
                                                            class="form-control">
                                                   <span class="err_msg" *ngIf="addValidationError.unfit">{{addValidationError.unfit}}</span>
                                                  </td>

                                                    <td><input type="number" [(ngModel)]="loginModel.unfit1" name="unfit1"
                                                            class="form-control">
                                                   <span class="err_msg" *ngIf="addValidationError.unfit1">{{addValidationError.unfit1}}</span>
                                                  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="sub_btn">
                                <!-- <button class="btn btn-primary" type="button">Add</button> -->
                                <button class="btn btn-primary" (click)="onSubmit()" type="button">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>