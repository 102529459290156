import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model } from '../../models/login_model';
import { Validation_model } from '../../models/Validation_model';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-add-interruption-focus-tips',
  templateUrl: './add-interruption-focus-tips.component.html',
  styleUrls: ['./add-interruption-focus-tips.component.scss']
})
export class AddInterruptionFocusTipsComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Interruption Added successfully'; 
  errorMessage='Something went wrong'; 
  record: any = [];
  constructor(private accountService: AccountService, private route: ActivatedRoute, private router: Router, private http: Http) { }

  ngOnInit(): void {
    this.loginModel = <Login_model>{
      type: '',
    }
    this.addValidationError = <Validation_model>{
      type: '',
    }

    this.getId = this.route.params.subscribe(params => {
      this.getId = params['id'];
    });
    this.isLoggedIn();
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if (this.loginModel.email == null) {
      this.router.navigate(['/']);
    }
  }
  add_interruption() {
    var valid = true;
    this.addValidationError.type = '';

    if (this.loginModel.type == "") {
      this.addValidationError.type = 'Please Enter Interuption Type';
      valid = false;
    }
    if (valid) {
      // debugger;
      console.log(this.loginModel);
      this.accountService.add_interruption(this.loginModel).then((response) => {
        if (response.status == true) {
          this.sucMsg = true;
          setTimeout (() => { this.router.navigate(['/show-interruption']);  }, 1000);
        } else {
          this.errMsg = true;
          this.router.navigate(['/']);
        }
      })
    }
  }
}


