<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">Users Enquiry</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Users Enquiry</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div class="search_bar">
                        <form class="d-flex align-items-center">
                            <input type="text" class="form-control" [(ngModel)]="loginModel.searchKey" name="searchKey" placeholder="Search...">
                            <button class="btn btn-primary" (click)="userListing()">Search</button>
                        </form>    
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <div class="table-box">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th><div class="t_respons_sno">S.no</div></th>
                                        <th><div class="t_respons_name">Name</div></th>
                                        <th><div class="t_respons_email">Email</div></th>
                                        <th><div class="t_respons_comment">Comment</div></th>
                                        <th><div class="t_respons_tatus">Status</div></th>
                                        <th><div class="t_respons_action">Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div class="loader_area">
                                        <span *ngIf="loaderShow">
                                            <app-loader class="table_loader"></app-loader>
                                        </span>
                                    </div>
                                    <tr *ngFor="let obj of record; let i = index ">
                                        <td>{{ (currentPage-1)*10+i+1 }}</td>
                                        <td *ngIf="obj.name==''">N/A</td>
                                        <td *ngIf="obj.name!=''">{{obj.name}}</td>
                                        <td>{{obj.email}}</td>
                                        <td>{{obj.comment}}</td>
                                        <td class="active" *ngIf="obj.status=='1'"><span>Pending</span></td>
                                        <td class="de_active" *ngIf="obj.status=='0'"><span>Resolved</span></td>
                                        <!-- <td> 
                                            <a routerLink="/edit_user/{{obj.id}}"></a>
                                            <a (click)="delete_user(obj.id,obj.name)">Delete</a>
                                        </td>  -->
                                        <td class="de-active" (click)="change_status(obj.id,obj.status,obj.name)"
                                            *ngIf="obj.status=='1'">Mark Resolved</td>

                                        <td class="de-active" (click)="change_status(obj.id,obj.status,obj.name)"
                                            *ngIf="obj.status=='0'">Resolved</td>   
                                    </tr>
                                    <tr *ngIf="recordcheck">
                                        <td colspan="7" class="text-center" style="color:red;">
                                            No record Found !
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation" class="mt-3">
                                <ul class="pagination">
                                    <li class="page-item disabled_back" *ngIf="currentPage==1"><a
                                            class="page-link">Previous</a>
                                    <li class="page-item" *ngIf="currentPage!=1" (click)="pageChange(currentPage-1)"><a
                                            class="page-link">Previous</a>
                                    </li>
                                    <li class="page-item" *ngFor='let in of counter(totalPages) ;let m = index'>
                                        <a class="page-link active" *ngIf="((m+1)==currentPage )">{{m+1}}</a>
                                        <a class="page-link"
                                            *ngIf="(m+1)!==currentPage && (m+1)>=(currentPage-2) && (m+1)<=(currentPage+2)"
                                            (click)="pageChange(m+1)">{{m+1}}</a>
                                    </li>
                                    <li class="page-item disabled_back" *ngIf="totalPages==currentPage">
                                        <a class="page-link">Next</a></li>
                                    <li class="page-item" *ngIf="totalPages!==currentPage"
                                        (click)="pageChange(currentPage+1)">
                                        <a class="page-link">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- end container -->
</div>
<app-footer></app-footer>