import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model } from '../../models/login_model';
import { Validation_model } from '../../models/Validation_model';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-add-exercise',
  templateUrl: './add-exercise.component.html',
  styleUrls: ['./add-exercise.component.scss']
})
export class AddExerciseComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Validation_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record Added successfully'; 
  errorMessage='Something went wrong'; 
  record: any = [];
  fit_level: any = [];
  exercise_points: any[];
  valid: boolean = true;
  // tempArr:any =[];
  checked: boolean = false;
  constructor(private accountService: AccountService, private route: ActivatedRoute, private router: Router, private http: Http) { }

  ngOnInit(): void {
    this.loginModel = <Login_model>{
      exercise: '',
      exercise_points: '',
      fitness_level: '',
      fit_level: '',
      fit: '',
      unfit: '',
      super_fit: '',
      fit1: '',
      unfit1: '',
      super_fit1: '',
      video_link: '',
      body_section: '',
      Equip: []
    }
    this.addValidationError = <Validation_model>{
      exercise: '',
      exercise_points: '',
      fitness_level: '',
    }

    this.getId = this.route.params.subscribe(params => {
      this.getId = params['id'];
    });
    this.isLoggedIn();
    this.get_equipments();

  }

  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if (this.loginModel.email == null) {
      this.router.navigate(['/']);
    }
  }
  

  onChangeCategory(index, cat) { // Use appropriate model type instead of any
   
    if (this.loginModel.Equip[index] == undefined || this.loginModel.Equip[index] == "") {
      console.log(cat);
      this.loginModel.Equip[index] = cat;
    } else {
      this.loginModel.Equip[index] = "";
    }
  }
  onSubmit() {
    var valid = true;
    this.addValidationError.body_section = '';
    this.addValidationError.video_link = '';
    this.addValidationError.unfit1 = '';
    this.addValidationError.unfit = '';
    this.addValidationError.fit1 = '';
    this.addValidationError.fit = '';
    this.addValidationError.super_fit1 = '';
    this.addValidationError.super_fit = '';
    this.addValidationError.body_section = '';
    this.addValidationError.video_link = '';
    this.addValidationError.exercise = '';


    if (this.loginModel.unfit1 == "") {
      this.addValidationError.unfit1 = 'Please add points';
      valid = false;
    }
    if (this.loginModel.unfit == "") {
      this.addValidationError.unfit = 'Please add unfit reps';
      valid = false;
    }


    if (this.loginModel.fit == "") {
      this.addValidationError.fit = 'Please add Fit Reps';
      valid = false;
    }
    if (this.loginModel.fit1 == "") {
      this.addValidationError.fit1 = 'Please add points';
      valid = false;
    }

    if (this.loginModel.super_fit == "") {
      this.addValidationError.super_fit = 'Please add Super fit Reps';
      valid = false;
    }
    if (this.loginModel.super_fit1 == "") {
      this.addValidationError.super_fit1 = 'Please add points';
      valid = false;
    }
  

    if (this.loginModel.exercise == "") {
      this.addValidationError.exercise = 'Please Enter Exercise Name';
      valid = false;
    }
    if (this.loginModel.video_link == "") {
      this.addValidationError.video_link = 'Please Enter Video Link';
      valid = false;
    }
    if (this.loginModel.body_section == "") {
      this.addValidationError.body_section = 'Please Enter Exercise Body Section';
      valid = false;
    }
   
    if (valid) {
      console.log(this.loginModel);
      this.accountService.add_exe(this.loginModel).then((response) => {
        if (response.status == true) {
          this.sucMsg = true;
          setTimeout (() => { this.router.navigate(['/view-exercise']);  }, 1500);
        } else {
          this.errMsg = true;
          this.router.navigate(['/']);
        }
        
      })
    } else {
      alert('Enter All Input Fields');
    }
  }

  set_value(value) {
    // will print someValue
    alert(value);
  }

  //method for get Equipment listing
  get_equipments() {
    this.accountService.get_equipment(this.loginModel).then((response) => {
      if (response.status == true) {
        this.record = response.result;
      } else {
        this.successMessage='Please Add Equipments';
      }
    })
  }
}



