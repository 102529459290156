<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">
        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Dashboard</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <!-- Users Count -->
            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/user']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-users" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 align-self-center text-center">
                                    <div class="m-l-10">
                                        <h5 class="mt-0 round-inner">{{ record }}</h5>
                                        <p class="mb-0 text-muted">Total Users</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <!-- User Enquiry Count -->
            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/user-enquiry']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 text-center align-self-center">
                                    <div class="m-l-10 ">
                                        <h5 class="mt-0 round-inner">{{ records }}</h5>
                                        <p class="mb-0 text-muted">Users Enquiry</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <!-- Active Recovery -->
            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/view-exercise']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-circle-o-notch" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 text-center align-self-center">
                                    <div class="m-l-10 ">
                                        <h5 class="mt-0 round-inner">{{ recovery }}</h5>
                                        <p class="mb-0 text-muted">Active Recovery</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <!--Productivity -->
            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/productivity-listing']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-cogs" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 text-center align-self-center">
                                    <div class="m-l-10 ">
                                        <h5 class="mt-0 round-inner">{{ productivity }}</h5>
                                        <p class="mb-0 text-muted">Productivity</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <!--Renewal Period -->
            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/renewal-period-listing']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-refresh" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 text-center align-self-center">
                                    <div class="m-l-10 ">
                                        <h5 class="mt-0 round-inner">{{ renewal }}</h5>
                                        <p class="mb-0 text-muted">Renewal Period</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <!--View Wearing Types -->
            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/show-wearing']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 text-center align-self-center">
                                    <div class="m-l-10 ">
                                        <h5 class="mt-0 round-inner">{{ wearing }}</h5>
                                        <p class="mb-0 text-muted">View Wearing</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-3">
                <a [routerLink]="['/manage-coupons']">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <div class="col-3 align-self-center">
                                    <div class="round">
                                        <i class="fa fa-tag" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-9 text-center align-self-center">
                                    <div class="m-l-10 ">
                                        <h5 class="mt-0 round-inner">{{ coupon }}</h5>
                                        <p class="mb-0 text-muted">Manage Coupons</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
        
        </div>
    </div> <!-- end container -->
</div>
<!-- end wrapper -->



<app-footer></app-footer>