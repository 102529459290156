<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a href="#">ThinkFit</a></li>
                            <li class="breadcrumb-item active">View User</li>
                        </ol>
                    </div>
                    <h4 class="page-title">View User</h4>
                </div>
            </div>
            <div class="col-md-12 mb-4">
                <div class="card">
                    <div class="card-header">
                        <h4>View User</h4>
                    </div>
                    
                    <div class="card-body">
                             <!-- Success msg html -->
           
                        <div class="row">
                            <div class="col-md-6 pb-4">
                                <h6>Name</h6>
                                <p>{{ loginModel.name }}</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Email</h6>
                                <p>{{ loginModel.email }}</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Gender</h6>
                                <p *ngIf="loginModel.gender!=null">{{ loginModel.gender }}</p>
                                <p *ngIf="loginModel.gender==null">NaN</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Date of Birth</h6>
                                <p *ngIf="loginModel.dob!=null"> {{ loginModel.dob }}</p>
                                <p *ngIf="loginModel.dob==null">NaN</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Focus Time</h6>
                                <p>{{ loginModel.default_time }}</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Fitness Points</h6>
                                <p>{{ this.loginModel.user_points }}</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Device Login</h6>
                                <p>{{ loginModel.device_type }}</p>
                            </div>
                           
                            <div class="col-md-6 pb-4">
                                <h6>Set time</h6>
                                <p>{{ loginModel.set_time }}</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Occupation</h6>
                                <p>{{ loginModel.occupation }}</p>
                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Wearing Type</h6>
                                <p *ngIf="loginModel.wearing_type!=null">{{ loginModel.wearing_type }}</p>
                                <p *ngIf="loginModel.wearing_type==null">NaN</p>

                            </div>
                            <div class="col-md-6 pb-4">
                                <h6>Fitness Level</h6>
                                <p>{{loginModel.fitness_status}}</p>
                            </div>

                            <div class="col-md-12 pb-2">
                                <h6>Equipment Available.</h6>
                                <p *ngFor="let obj of record; let i = index ">
                                  {{ i+1 }}. <span>{{ obj.equipment_type }} <i class="fa fa-check" aria-hidden="true"></i></span>
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add Bonus Point  -->
            <!-- <div class="card-header d-flex justify-content-between align-items-center">
                <div class="search_bar">
                    <form class="d-flex align-items-center">
                        <input type="text" class="form-control" [(ngModel)]="loginModel.searchKey"
                            name="searchKey" placeholder="Search...">
                        <button class="btn btn-primary" (click)="Bonus()">Search</button>
                    </form>
                </div>
            </div> -->
              <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center mb-4">
                            <a  class="head-btn" data-toggle="modal" data-target="#add-code"><i  class="fas fa-plus mr-2"></i>Add Bonus </a>
                        </div>
                           <div class="table-box">
                       <div class="alert alert-success" role="alert" *ngIf="sucMsg1">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg1">
                            {{ errorMessage }}
                            </div>
                        </div>
                    </div>
                    <div class="table-box">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="t_respons_sno">S.no</div>
                                    </th>
                                    <th class="sort_menu">
                                        <div class="t_respons_name">
                                            Bonus
                                        </div>
                                    </th>
                                   
                                    <th>
                                        <div class="t_validTo">Tags</div>
                                    </th>
                                    <th>
                                        <div class="t_validTo">Description</div>
                                    </th>
                                    <th>
                                        <div class="t_dis_type">Created At</div>
                                    </th>
                                 
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of code_record; let i = index ">
                                    <td>{{ (currentPage-1)*10+i+1 }}</td>
                                    <td *ngIf="obj.points!=null">{{obj.points}}</td>
                                    <td *ngIf="obj.points==null">NaN</td>
                                    <td *ngIf="obj.tags!=null">{{obj.tags}}</td>
                                    <td *ngIf="obj.tags==null">NaN</td>
                                    <td *ngIf="obj.exercise!=null">{{obj.exercise}}</td>
                                    <td *ngIf="obj.exercise==null">NaN</td>
                                    <td *ngIf="obj.createdAt!=null">{{obj.createdAt}}</td>
                                    <td *ngIf="obj.createdAt==null">NaN</td>
                                </tr>
                                <tr *ngIf="recordcheck">
                                    <td colspan="7" class="text-center" style="color:red;">
                                        No record Found !
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <nav aria-label="Page navigation" class="mt-3">
                            <ul class="pagination">
                                <li class="page-item disabled_back" *ngIf="currentPage==1"><a
                                        class="page-link">Previous</a>
                                <li class="page-item" *ngIf="currentPage!=1" (click)="pageChange(currentPage-1)"><a
                                        class="page-link">Previous</a>
                                </li>
                                <li class="page-item" *ngFor='let in of counter(totalPages) ;let m = index'>
                                    <a class="page-link active" *ngIf="((m+1)==currentPage )">{{m+1}}</a>
                                    <a class="page-link"
                                        *ngIf="(m+1)!==currentPage && (m+1)>=(currentPage-2) && (m+1)<=(currentPage+2)"
                                        (click)="pageChange(m+1)">{{m+1}}</a>
                                </li>

                                <li class="page-item disabled_back" *ngIf="totalPages==currentPage">
                                    <a class="page-link">Next</a></li>
                                <li class="page-item" *ngIf="totalPages!==currentPage"
                                    (click)="pageChange(currentPage+1)">
                                    <a class="page-link">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div class="modal" id="add-code" tabindex="-1" aria-labelledby="addcodelabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addcodelabel">Add Bonus Point</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form action="">
                <div class="form-group">
                    <label for="title">Point</label>
                    <input type="number" class="form-control" [(ngModel)]="loginModel.points" name="points">
                    <span class="err_msg" *ngIf="addValidationError.points">{{addValidationError.points}}</span>
                </div>
                <div class="form-group">
                    <label for="title">Description</label>
                    <input type="textarea" class="form-control" [(ngModel)]="loginModel.point_description" name="point_description">
                    <span class="err_msg" *ngIf="addValidationError.point_description">{{addValidationError.point_description}}</span>
                </div>
                
                <input type="hidden" class="form-control" [(ngModel)]="loginModel.id" name="id">
             
                <button type="submit" (click)="add_points()" class="btn btn-primary px-4 float-right">Add Point</button>
            </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-code" tabindex="-1" aria-labelledby="addcodelabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addcodelabel">Edit Code</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form action="">
                <div class="form-group">
                    <label for="title">Code</label>
                    <input type="text" class="form-control">
                </div>
             
                <button type="submit" class="btn btn-primary px-4 float-right">SUBMIT</button>
            </form>
        </div>
      </div>
    </div>
  </div>
<app-footer></app-footer>