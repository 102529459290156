<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Thinkfit</a></li>
                            <li class="breadcrumb-item active">Application Settings</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Apps Settings</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/view-exercise']"></a>
                    <i class="fa fa-circle-o-notch application_icon" aria-hidden="true"></i>
                    <p>Active Recovery</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/productivity-listing']"></a>
                    <i class="fa fa-cogs application_icon" aria-hidden="true"></i>
                    <p>Productivity</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/renewal-period-listing']"></a>
                    <i class="fa fa-refresh application_icon" aria-hidden="true"></i>
                    <p>Renewal Period</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/show-wearing']"></a>
                    <i class="fa fa-eye application_icon" aria-hidden="true"></i>
                    <p>View Wearing Type</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/show-interruption']"></a>
                    <i class="fa fa-plus-circle application_icon" aria-hidden="true"></i>
                    <p>Add | Control Interruptions</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/show-equipment']"></a>
                    <i class="fa fa-question-circle-o application_icon" aria-hidden="true"></i>
                    <p>Add Equipment(Question)</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 px-0">
                <div class="project-box">
                    <a [routerLink]="['/showInterruptionFeedbck']"></a>
                    <i class="fa fa-comments application_icon" aria-hidden="true"></i>
                    <p>Show Interruption Feedback</p>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>

