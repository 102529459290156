<!-- <app-loader></app-loader> -->
<app-header></app-header>

<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/renewal-period-listing']">Renewal Period Listing</a></li>
                            <li class="breadcrumb-item active">Edit  Renewal Exercise</li>
                        </ol>
                    </div>
                    <h4 class="page-title">Edit Renewal Exercise</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ successMessage }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                    <form class="form-horizontal" action="">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group"> 
                                    <label>Edit Exercise</label>
                                    <input type="text"  [(ngModel)]="loginModel.renew_exercise" name="renew_exercise"
                                        class="form-control" required>
                                     <span class="err_msg" *ngIf="addValidationError.renew_exercise">{{addValidationError.renew_exercise}}</span> 
                                </div>
                                <div class="form-group">
                                    <label>Edit Video Link</label>
                                    <input type="text" [(ngModel)]="loginModel.link" name="link" class="form-control" required>
                                    <span class="err_msg" *ngIf="addValidationError.link">{{addValidationError.link}}</span>
                                </div>
                                <input type="hidden" [(ngModel)]="loginModel.id" name="id" required="" class="form-control">
                          
                                <div class="form-group">
                                    <label>Description(optional)</label>
                                    <input type="text" [(ngModel)]="loginModel.description" name="description" class="form-control" >
                                </div>
                            </div>
                        
                        </div>

                        <div class="sub_btn">
                            <!-- <button class="btn btn-primary" type="button">Add</button> -->
                             <button class="btn btn-primary" (click)="update_renewal_period()" type="button">Edit Renewal</button> 
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>

    </div> <!-- end container -->
</div>
<app-footer></app-footer>