import { Component, OnInit } from '@angular/core';
import {Http , Headers } from  '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login_model} from'../../models/login_model';
import{ AccountService} from'../../services/account.service';

@Component({
  selector: 'app-edit-interrruption',
  templateUrl: './edit-interrruption.component.html',
  styleUrls: ['./edit-interrruption.component.scss']
})

export class EditInterrruptionComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  public getId: any = [];
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
 record:any=[];
 loaderShow: boolean = false
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http ) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      type: '',
    }
    this.addValidationError = <Login_model>{
      type: '',
      }
     this.route.params.subscribe(params => {
       this.getId = params['id'];
     });
     this.getinterruption();
     this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }

  getinterruption() {
    this.loaderShow=true;
    this.accountService.getinterruption(this.getId).then((response) => {
      // debugger;
      console.log(response);
        if(response.code==200){
         this.record=response.result;
         this.loginModel.type=this.record.focus_tip;
         this.loginModel.id=this.record.id;
         this.loaderShow=false;
        
        }else{
          this.errMsg = false;
          //this.router.navigate(['/user']);
        }
      })
  }
 
  update_interruption(){
    this.loaderShow=true;
    var valid=true;
    this.addValidationError.type	='';
    if(this.loginModel.type==""){
      this.addValidationError.type='Please Enter Interruption Type';
      valid=false; 
    }
    if(valid){ 
      this.accountService.update_interruption(this.loginModel).then((response)=>{
        if (response.status == true) {
             this.sucMsg=true;
             setTimeout (() => {  
              this.sucMsg = false; 
               this.router.navigate(['/show-interruption']); 
              }, 2000);
         } else {
             this.errMsg = true;
             this.errorMessage;
                 setTimeout (() => {
                    this.sucMsg = false;
               this.router.navigate(['/show-interruption']); 
             }, 2000);
         }
      })
    }
    }
}




