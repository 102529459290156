import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { AccountService } from '../../services/account.service';
import { Headers, Http } from '@angular/http';
import { Cookie } from 'ng2-cookies';
import { Login_model } from '../../models/login_model';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  constructor(private accountService:AccountService,private activatedRoute: ActivatedRoute,private router: Router,private http: Http) { }
  sucMsg:boolean=false;
  errMsg:string='';
  getId: string;
  c_password:string;
  ngOnInit(): void {
    this.loginModel=<Login_model>{
      c_password: '',
      password:'',
    }
    this.activatedRoute.params.subscribe(params => {
      this.getId = params['token'];
     //  this.getUser()
    });
    this.addValidationError = <Login_model>{
      c_password: '',
      password:'',
    }
   // this.isLoggedIn();
  }
  
  // isLoggedIn() {
  //   this.loginModel.email = localStorage.getItem('email');
  //   if(this.loginModel.email == null){
  //   this.router.navigate(['/']);
  //   }
  //   }

  update_recover_password(){
    var valid=true;
    this.addValidationError.password='';
    this.addValidationError.c_password='';
    if(this.loginModel.password==""){
      this.addValidationError.password='Please Enter Password';
      valid=false; 
    }
    if(this.loginModel.c_password==""){
      this.addValidationError.c_password='Please Enter Confirm Password';
      valid=false; 
    }
    if(this.loginModel.password==this.loginModel.c_password){
     // this.addValidationError.c_password='';
    }else{
      this.addValidationError.c_password='Password does not match';
      valid=false; 
    }
    if(valid){
      this.accountService.update_recover_password(this.getId, this.loginModel.password).then((response)=>{
        if(response.status==true){
          this.router.navigate(['/thanks-message']);
          this.addValidationError.success='Your Password has been Updated Successfully';
          this.sucMsg = true;
        }else{
          this.errMsg = 'Wrong Credentials';
          this.router.navigate(['/']);
        }
      })
    }
    }

}

