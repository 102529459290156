<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12 ">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">View Offer </li>
                        </ol>
                    </div>
                    <h4 class="page-title">View Offer Detail({{this.record.title}})</h4>

                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12 mb-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-3">
                                <h6>Discount Type</h6>
                                <p>{{this.record.discount_type}}</p>
                            </div>
                            <div class="col-3">
                                <h6>Discount Value</h6>
                                <p>{{this.record.discount_value}}</p>
                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <h6>Valid From</h6>
                                <p>{{this.record.valid_from}}</p>
                            </div>
                            <div class="col-3">
                                <h6>Valid To</h6>
                                <p>{{this.record.valid_to}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10">
                                <h6>Term & Conditions</h6>
                                <p>{{this.record.terms_condition}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10">
                                <h6>Prize in Fitness Point*</h6>
                                <p>{{this.record.prize_in_fitness_point}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10">
                                <h6> Redeem Instructions </h6>
                                <p>{{this.record.redeem_instructions}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10">
                                <h6>Description</h6>
                                <p>{{this.record.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                            {{ sucMsg }}
                            </div>
                            <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                            {{ errorMessage }}
                            </div>
                        <div class="d-flex align-items-center mb-4">
                            <a  class="head-btn" data-toggle="modal" data-target="#add-code"><i  class="fas fa-plus mr-2"></i>Add Code </a>
                        </div>

                        <div class="table-box">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="t_respons_sno">S.no</div>
                                        </th>
                                        <th class="sort_menu">
                                            <div class="t_respons_name">
                                                Code
                                            </div>
                                        </th>
                                        <th class="sort_menu">
                                            <div class="t_validfrom">Status</div>
                                        </th>
                                        <th>
                                            <div class="t_validTo">Action</div>
                                        </th>

                                        <th>
                                            <div class="t_dis_type">Edit</div>
                                        </th>
                                     
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of code_record; let i = index ">
                                        <td>{{ (currentPage-1)*10+i+1 }}</td>
                                        <td *ngIf="obj.code!=null">{{obj.code}}</td>
                                        <td *ngIf="obj.code==null">NaN</td>
                                
                                        <td *ngIf="obj.coupon_status==1">Un-Used</td>
                                        <td *ngIf="obj.coupon_status==2">Used</td>
                                        <td *ngIf="obj.status==1" (click)="change_codes_status(obj.id,obj.status)" >
                                        <i class="fa fa-circle active mr-2" aria-hidden="true"></i>Enable
                                        </td>
                                        <td *ngIf="obj.status==0" (click)="change_codes_status(obj.id,obj.status)">
                                            <i class="fa fa-circle deactive mr-2" aria-hidden="true"></i>Disable
                                        </td>

                                        <td>
                                            <div class="btn_area">
                                                <a (click)="code_update(obj.id,obj.code)" data-toggle="modal" data-target="#edit-code"><i class="far fa-edit"></i></a>
                                               <a (click)="code_delete(obj.id,obj.code)"><i
                                                    class="fas fa-trash-alt"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="recordcheck">
                                        <td colspan="7" class="text-center" style="color:red;">
                                            No record Found !
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <nav aria-label="Page navigation" class="mt-3">
                                <ul class="pagination">
                                    <li class="page-item disabled_back" *ngIf="currentPage==1"><a
                                            class="page-link">Previous</a>
                                    <li class="page-item" *ngIf="currentPage!=1" (click)="pageChange(currentPage-1)"><a
                                            class="page-link">Previous</a>
                                    </li>
                                    <li class="page-item" *ngFor='let in of counter(totalPages) ;let m = index'>
                                        <a class="page-link active" *ngIf="((m+1)==currentPage )">{{m+1}}</a>
                                        <a class="page-link"
                                            *ngIf="(m+1)!==currentPage && (m+1)>=(currentPage-2) && (m+1)<=(currentPage+2)"
                                            (click)="pageChange(m+1)">{{m+1}}</a>
                                    </li>

                                    <li class="page-item disabled_back" *ngIf="totalPages==currentPage">
                                        <a class="page-link">Next</a></li>
                                    <li class="page-item" *ngIf="totalPages!==currentPage"
                                        (click)="pageChange(currentPage+1)">
                                        <a class="page-link">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
    </div> <!-- end container -->
</div>
<div class="modal" id="add-code" tabindex="-1" aria-labelledby="addcodelabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addcodelabel">Add Code</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form action="">
                <div class="form-group">
                    <label for="title">Code</label>
                    <input type="text" [(ngModel)]="loginModel.code" name="code" class="form-control">
                    <span class="err_msg" *ngIf="addValidationError.code">{{addValidationError.code}}</span>
                </div>
                <button type="submit" (click)="add_code()"  class="btn btn-primary px-4 float-right">SUBMIT</button>
            </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="edit-code" tabindex="-1" aria-labelledby="addcodelabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addcodelabel">Edit Code</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form action="">
               
                <div class="form-group">
                    <label>Code</label>
                    <input type="text" [(ngModel)]="loginModel.updated_code" name="updated_code"
                        class="form-control" required>
                        
                    <span class="err_msg"
                        *ngIf="addValidationError.updated_code">{{addValidationError.updated_code}}</span>
                </div>
                <input type="hidden" [(ngModel)]="loginModel.update_id" name="update_id" required=""
                    class="form-control">
             
                <button type="submit" (click)="update_code_details()" class="btn btn-primary px-4 float-right">SUBMIT</button>
            </form>
        </div>
      </div>
    </div>
  </div>

<app-footer></app-footer>