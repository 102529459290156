<!-- <app-loader></app-loader> -->
<app-header></app-header>
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/user']">ThinkFit</a></li>
                            <li class="breadcrumb-item active">Edit User</li>
                        </ol>
                    </div>
                    <h4 class="page-title"> Edit User</h4>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                    {{ successMessage }}
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                    {{ errorMessage }}
                </div>
                <form>
                    <div class="card">
                        <div class="card-header">
                            <h4>Edit User</h4>
                        </div>
                        <div class="card-body">
                            <div class="row pt-2">
                                <div class="col-md-8 col-lg-6 d-sm-flex align-items-center">
                                    <label>Name :</label>
                                    <div class="w-100">
                                        <input type="text" [(ngModel)]="loginModel.name" name="name" required=""
                                            class="form-control">
                                        <span class="err_msg"
                                            *ngIf="addValidationError.name">{{addValidationError.name}}
                                        </span>
                                    </div>
                                </div>


                            </div>
                            <div class="row pt-2">
                                <div class="col-md-8 col-lg-6 d-sm-flex align-items-center">
                                    <label>Email :</label>
                                    <div class="w-100">
                                        <input type="Email" [(ngModel)]="loginModel.email" name="email" required=""
                                            class="form-control">
                                        <span class="err_msg"
                                            *ngIf="addValidationError.email">{{addValidationError.email}}
                                        </span>
                                    </div>

                                </div>

                            </div>
                            <div class="row pt-2">
                                <div class="col-md-8 col-lg-6 d-sm-flex align-items-center">
                                    <label>DOB :</label>
                                    <div class="w-100">
                                        <input type="text" [(ngModel)]="loginModel.dob" name="dob" required=""
                                            class="form-control">

                                        <input type="hidden" [(ngModel)]="loginModel.id" name="id" required=""
                                            class="form-control">
                                        <span class="err_msg" *ngIf="addValidationError.dob">{{addValidationError.dob}}
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="row pt-2">
                                <div class="col-md-8 col-lg-6 d-sm-flex align-items-center">
                                    <label>Gender :</label>
                                    <div class="w-100">
                                        <select class="form-control" name="gender" [(ngModel)]="loginModel.gender"
                                            required="">
                                            <option selected></option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <span class="err_msg"
                                            *ngIf="addValidationError.gender">{{addValidationError.gender}}
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <button class="btn btn-primary mt-4" (click)="update_user()">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> <!-- end container -->
</div>
<app-footer></app-footer>