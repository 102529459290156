import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Login_model } from '../models/login_model';
import { AccountService } from '../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Login_model;
  private headers = new Headers();
  constructor(private accountService: AccountService, private route: ActivatedRoute, private router: Router, private http: Http) { }
  sucMsg_bonus: string = null;
  errMsg_bonus: string = null;
   sucMsg: string = null;
  errMsg: string = null;
  successMessage='Bonus Added successfully'; 
  errorMessage='Something went wrong';
  record: any = [];
  multi_record: any = [];
  detailed_record: any = [];
  getId: string;
  sucMsg1: boolean = false;
  errMsg1: boolean = false;

    //  start
  records:any=[];
  code_record: any=[];
  total : number= 0;
  totalPages : number= 0;
  currentPage:number=1;
  page : number = 1;
  key: string = 'recommend'; //set default
  reverse: boolean = false;
  loaderShow: boolean = false;
  searchKey: '';
  recordcheck=false;
  // close

  ngOnInit(): void {
    this.loginModel = <Login_model>{
      email: '',
      name: '',
      searchKey: '',
       points: '',
       point_description:'',
       tags:'',
    }
      this.addValidationError = <Login_model>{
      points: '',
      point_description:'',
      tags:'',
    }

    

    this.route.params.subscribe(params => {
      this.getId = params['id'];
      //  this.getUser()
    });
    this.isLoggedIn()
    this.view_user(this.getId)
    this.user_point(this.getId)
    this.detailed_info(this.getId)
    this.user_wearing_type(this.getId)
    this.Bonus()

  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if (this.loginModel.email == null) {
      this.router.navigate(['/']);
    }
  }

  view_user(id) {
    //alert(id);
    var valid = true;
    this.accountService.view_users(id).then((response) => {
      if (response.code == 201) {
        this.record = response.result;
        this.loginModel.name = this.record.name;
        this.loginModel.email = this.record.email;
        this.loginModel.dob = this.record.dob;
        this.loginModel.gender = this.record.gender;
        this.loginModel.id = this.record.id;
        this.loginModel.device_type = this.record.device_type;
        this.loginModel.default_time = this.record.default_time;
        this.loginModel.fitness_status = this.record.fitness_status;
        this.loginModel.user_points = this.record.user_points;

        this.sucMsg = 'Record shown';
      } else {
        this.errMsg = 'Wrong Credentials';
        this.router.navigate(['/user']);
      }
    })
  }

  user_point(id) {
    var valid = true;
    // debugger;
    this.accountService.show_point_sum(id).then((response) => {

      if (response.status == true) {
        this.record = response.result;
        this.loginModel.totalSum = this.record.totalSum;
        //alert(this.record.totalSum);
        this.sucMsg = 'Record shown';
      } else {
        this.errMsg = 'Wrong Credentials';
      //  this.router.navigate(['/user']);
      }
    })
  }

  show_task(id) {
    var valid = true;
    //debugger;
    this.accountService.show_tasks(id).then((response) => {
      if (response.status == true) {
        this.multi_record = response.result;
        this.sucMsg = 'Record shown';
      } else {
        this.errMsg = 'Wrong Credentials';
       // this.router.navigate(['/user']);
      }
    })
  }

  detailed_info(id) {
    var valid = true;
    this.accountService.detailed_infos(id).then((response) => {
      if (response.status == true) {
        this.detailed_record = response.result;
        this.loginModel.occupation = this.detailed_record.occupation;
        var myString = this.detailed_record.focus_days;
        myArray=Array();
        var myArray = myString.split(',');
       myArray.sort(function (a, b) {
          return a - b;
        });
        this.loginModel.set_time = this.detailed_record.set_time;
        this.sucMsg = 'Record shown';
      } else {
        this.errMsg = 'Wrong Credentials';
        //this.router.navigate(['/user']);
      }
    })
  }

  user_wearing_type(id) {
    var valid = true;
    this.accountService.user_wearing_type(id).then((response) => {
      // debugger; 
      console.log(response)
      if (response.status == true) {
        this.record = response.result;
        console.log(this.record);
        this.loginModel.wearing_type = this.record[0].wearing_type;
        this.sucMsg = 'Record shown';
      } else {
        this.errMsg = 'Wrong Credentials';
      //  this.router.navigate(['/user']);
      }
    })
  }

  // work_station_info(id) {
  //   var valid = true;
  //   this.accountService.show_user_workstation_infos(id).then((response) => {
  //     if (response.status == true) {
  //       this.record = response.result;
  //       this.loginModel.space_available = this.record.space_available;
  //       this.loginModel.mat_available = this.record.mat_available;
  //       this.loginModel.stairs = this.record.stairs;
  //       this.loginModel.resistance_band = this.record.resistance_band;
  //       this.sucMsg = 'Record shown';
  //     } else {
  //       this.errMsg = 'Wrong Credentials';
  //       this.router.navigate(['/user']);
  //     }
  //   })
  // }

  //Add Points



   add_points(){
        var valid=true;
        this.addValidationError.points='';
        if(this.loginModel.points==""){
          this.addValidationError.points='Please Enter Point';
          valid=false; 
        }
        if(this.loginModel.point_description==""){
          this.addValidationError.point_description='Please Enter Point Description';
          valid=false; 
        }
       

        if(valid){
          this.loginModel.id= this.getId;
        this.loginModel.exercise=this.loginModel.point_description;
         this.accountService.add_points(this.loginModel).then((response)=>{
         this.loginModel.points='';
           if (response.status == true) {
       this.sucMsg1 = true;
          setTimeout (() => { this.sucMsg1 = false; this.router.navigate(['/view-user/'+this.getId]);  }, 2000);
          this.Bonus();
        } else {
          this.errMsg1 = true;
          setTimeout (() => {  this.errMsg1 = false; this.router.navigate(['/view-user/'+this.getId]);  }, 2000);
          this.Bonus();
        }
          })
         }
      }

      // coupon listing start
   Bonus()
   {
    this.loaderShow = true;
    this.loginModel.id =  this.getId;
     this.loginModel.page = this.currentPage;
     this.accountService.getallBonus(this.loginModel).then((response)=>{

       if(response.status==true){
         this.code_record=response.result.rows;
         this.total = response.result.count;
          this.totalPages=Math.ceil( response.result.count/10);
          this.loaderShow = false;
          this.recordcheck=false;
          if(this.total == 0){
            this.code_record=[];
           this.recordcheck=true;
          }
       }else{
         this.loaderShow = false;
         this.recordcheck=true;
         this.router.navigate(['/view-user/'+this.getId]);
       }
     })
   }  
 
   pageChange(currentPage){
     this.currentPage=currentPage;
     if(this.loginModel.searchKey==""){
     this.Bonus();
     }else{
       this.Bonus();
     } 
   }
 
   counter(i: number) {
     return new Array(i);
   }




}
