  <!-- Navigation Bar-->
  <header id="topnav" class="navbar-expand-lg">
    <div class="topbar-main">
        <div class="container-fluid">
            <div class="logo">
                <a [routerLink]="['/dashboard']" class="logo">
                    <!-- <img src="assets/images/logo-sm.png" alt="" class="logo-small"> -->
                    <img src="assets/images/logo.svg" alt=""class="logo-large">
                </a>
            </div>
            <!-- End Logo container-->


            <div class="menu-extras topbar-custom">

                <ul class="list-inline float-right mb-0">

                    <!-- User-->
                    <li class="list-inline-item dropdown notification-list">
                        <a class="nav-link dropdown-toggle arrow-none waves-effect nav-user user_drop">
                            <img src="assets/images/user.png" alt="user" class="rounded-circle">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                            <!-- item-->
                            <div class="dropdown-item noti-title">
                                <h5>Welcome</h5>
                            </div>
                            <a class="dropdown-item" [routerLink]="['/change_password']"><i class="mdi mdi-account-circle m-r-5 text-muted"></i>Admin Profile</a>
                            <a (click)="logout()" class="dropdown-item logout_btn"><i class="mdi mdi-logout m-r-5 text-muted"></i> Logout</a> 
                       
                           <!-- <button (click)="logout()" type="button" class="btn btn-primary w-100"><i class="mdi mdi-logout m-r-5 text-muted"></i> Logout</button>  -->
                        </div>
                    </li>
                    <li class="menu-item list-inline-item">
                        <!-- Mobile menu toggle-->
                        <a class="navbar-toggle nav-link" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                        <!-- End mobile menu toggle-->
                    </li>

                </ul>
            </div>
            <!-- end menu-extras -->

            <div class="clearfix"></div>

        </div> <!-- end container -->
    </div>
    <!-- end topbar-main -->

    <!-- MENU Start -->
    <div class="navbar-custom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- Navigation Menu-->
                <ul class="navigation-menu">

                    <li class="has-submenu" [routerLink]="['/dashboard']" style="cursor: pointer;">
                        <a ><i class="mdi mdi-airplay"></i>Dashboard</a>
                    </li>

                    <li class="has-submenu" [routerLink]="['/user']" style="cursor: pointer;">
                        <a ><i class="mdi mdi-account"></i>Users</a>
                    </li>

                    <li class="has-submenu" [routerLink]="['/user-enquiry']" style="cursor: pointer;">
                        <a ><i class="mdi mdi-account"></i>User Enquiry</a>
                    </li>

                    <li class="has-submenu" [routerLink]="['/application-setting']" style="cursor: pointer;">
                        <a ><i class="mdi mdi-google-pages"></i>Application Settings</a>
                    </li>

                    <li class="has-submenu" [routerLink]="['/manage-coupons']" style="cursor: pointer;">
                        <a  ><i class="fa fa-tag" aria-hidden="true"></i>Manage Coupons</a>
                    </li> 
                    <li class="has-submenu" [routerLink]="['/about-us']" style="cursor: pointer;">
                        <a  ><i class="mdi mdi-google-pages"></i>About Us</a>
                    </li> 
                    <li class="has-submenu" [routerLink]="['/privacy-policy']" style="cursor: pointer;">
                        <a  ><i class="mdi mdi-google-pages"></i>Terms & Conditions</a>
                    </li> 
                  
                </ul>
                <!-- End navigation menu -->
            </div> <!-- end #navigation -->
        </div> <!-- end container -->
    </div> <!-- end navbar-custom -->
</header>
<!-- End Navigation Bar-->
