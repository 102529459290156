<!-- Begin page -->
<div class="accountbg"></div>
<div class="wrapper-page">

    <div class="card">
        <div class="card-body">
            <a href="#" class="account-logo"><img src="assets/images/logo.svg" alt=""></a>
            <h3 class="text-center mt-0 m-b-15">
                Forget Password
            </h3>
            <div class="p-3">
                <div class="alert alert-success" role="alert" *ngIf="sucMsg">
                    Password recovery link has been sent to your email 
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="errMsg">
                    Email does not Exists
                </div>
                <form class="form-horizontal m-t-20" action="" [formGroup]="EmailFrom">
                    <div class="form-group row">
                        <div class="col-12">
                            <!-- <input class="form-control" type="text" name="email" [(ngModel)]="loginModel.email" required="" placeholder="Email">
                                    <span *ngIf="addValidationError.email">{{addValidationError.email}}</span> -->
                            <input class="form-control" type="text" name="email" formControlName="email"
                                placeholder="Email">
                            <span style="color: red;"
                                *ngIf="isSubmitted && EmailFrom.controls['email'].errors?.required">Please Enter
                                Email</span>
                            <span style="color: red;"
                                *ngIf="isSubmitted && EmailFrom.controls['email'].errors?.email">Please Enter Valid
                                Email</span>
                        </div>
                    </div>

                    <div class="form-group text-center row m-t-20">
                        <div class="col-12">
                            <button style="background-color: #fb7e21;" class="login_btn" (click)="forgot()" type="button">Submit</button>
                            <!-- <span *ngIf="errMsg">{{ errMsg }}</span> -->
                        </div>
                    </div>
                    <div class="form-group m-t-10 mb-0 row">
                        <div class="col-sm-7 m-t-10">
                            <a href routerLink="/" class="text-muted"><i class="mdi mdi-lock"></i>
                                <small style="font-size: 13px;">Login</small></a>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>