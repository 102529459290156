import { Component, OnInit } from '@angular/core';
import{ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Route } from '@angular/compiler/src/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor( private router:Router
    ) { }

  ngOnInit(): void {
    
  }

logout(){
  localStorage.clear();
  this.router.navigate(['/']);
}

  ngAfterViewInit(){
    // debugger;
    $('.user_drop').click(function() {
      $('.profile-dropdown').toggleClass('drop_open')
    });
  }
}
