import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-detailed-view-exercise',
  templateUrl: './detailed-view-exercise.component.html',
  styleUrls: ['./detailed-view-exercise.component.scss']
})
export class DetailedViewExerciseComponent implements OnInit {
  public loginModel: Login_model;
  public addValidationError: Login_model;
  private headers = new Headers();
  constructor(private accountService: AccountService, private route: ActivatedRoute, private router: Router, private http: Http) { }
  sucMsg: string = null;
  errMsg: string = null;
  successMessage = 'Record updated Successfully';
  record: any = [];
  multi_record: any = [];
  detailed_record: any = [];
  getId: string;

  ngOnInit(): void {
    this.loginModel = <Login_model>{
      email: '',
      name: '',
      searchKey: ''
    }

    this.route.params.subscribe(params => {
      this.getId = params['id'];
      //  this.getUser()
    });
   this.isLoggedIn()
   this.user_detailed_view(this.getId);
   this.get_detailed_equipment(this.getId);
   this.get_fitness_level(this.getId);
    
  }
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if (this.loginModel.email == null) {
      this.router.navigate(['/']);
    }
  }

  user_detailed_view(id){
      this.accountService.user_detailed_views(id).then((response) => {
        if (response.status == true) {
          this.record = response.result;
          this.loginModel.exercise = this.record.exercise;
          this.loginModel.body_section = this.record.body_section;
          this.loginModel.video_link = this.record.video_link;
          this.sucMsg = 'Record shown';
        } else {
          this.errMsg = 'Wrong Credentials';
          this.router.navigate(['/user']);
        }
      })
  }
  get_detailed_equipment(id){
    this.accountService.get_detailed_equipments(id).then((response) => {
      if (response.status == true) {
        this.multi_record = response.result;
        this.sucMsg = 'Record shown';
      } else {
        this.errMsg = 'Wrong Credentials';
        this.router.navigate(['/user']);
      }
    })
}

get_fitness_level(id){
  this.accountService.get_fitness_level_view(id).then((response) => {
    // debugger;
    console.log(response)
    if (response.status == true) {
      this.record = response.result;
      this.sucMsg = 'Record shown';
    } else {
      this.errMsg = 'Wrong Credentials';
      this.router.navigate(['/user']);
    }
  })
}

  



}

