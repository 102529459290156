import { Component, OnInit } from '@angular/core';
//import { FormBuilder } from '@angular/forms';
import {Http , Headers } from  '@angular/http';
import { Login_model } from '../../models/login_model';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-show-equipment',
  templateUrl: './show-equipment.component.html',
  styleUrls: ['./show-equipment.component.scss']
})

export class ShowEquipmentComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public loginModel:Login_model;
  public addValidationError:Login_model;
  private headers = new Headers();
  sucMsg: boolean = false;
  errMsg:boolean = false; 
  successMessage='Record updated successfully'; 
  errorMessage='Something went wrong'; 
  record:any=[];
  records:any=[];
  public getId: any = [];
  total : number= 0;
  totalPages : number= 0;
  currentPage:number=1;
  page : number = 1;
  key: string = 'recommend'; //set default
  reverse: boolean = false;
  loaderShow: boolean = false;
  searchKey: '';
  recordcheck=true;
  constructor(private accountService:AccountService,private route: ActivatedRoute,private router: Router,private http: Http) { }

  ngOnInit(): void {
    this.loginModel=<Login_model>{
      page:1,
		  sortKey: '',
    }
    this.view_equipment_type();
    this.isLoggedIn();
  }
  
  isLoggedIn() {
    this.loginModel.email = localStorage.getItem('email');
    if(this.loginModel.email == null){
    this.router.navigate(['/']);
    }
    }
  view_equipment_type()
  {
   this.loaderShow = true;
    this.loginModel.page = this.currentPage;
    this.accountService.view_equipment(this.loginModel).then((response)=>{
     
      if(response.status==true){
        this.record=response.result.rows;
        this.total = response.result.count;
         this.totalPages=Math.ceil( response.result.count/10);
         this.loaderShow = false;
         this.recordcheck=false;
         if(this.total == 0){
           this.record=[];
          this.recordcheck=true;
         }
      }else{
        this.loaderShow = false;
        this.recordcheck=true;
        this.router.navigate(['/']);
      }
    })
  }  

  pageChange(currentPage){
    this.currentPage=currentPage;
    if(this.loginModel.searchKey==""){
    this.view_equipment_type();
    }else{
      this.view_equipment_type();
    } 
  }

  counter(i: number) {
    return new Array(i);
  }

  equipment_delete(id){
    if(confirm("Are you sure to delete !")) {
      var valid=true;
      this.loaderShow = true;
     this.accountService.delete_equipment(id).then((response)=>{
      this.loaderShow = false;
        if(response.code==200){
          this.sucMsg = true;
                this.successMessage='Equipment Deleted Successfully';
                this.view_equipment_type();
                setTimeout (() => {  this.sucMsg = false }, 1000);
        }else{
          this.errMsg = true;
          this.router.navigate(['/']);
        }
      })
    }
  }

  change_status(id,status){
        var valid=true;
        this.loaderShow = true;
        this.accountService.change_equipment(id,status).then((response)=>{
         this.loaderShow = false;
            if(response.code==200){
              if(status==1){
                this.successMessage='Equipment Disabled Successfully';
              }else{
                this.successMessage='Equipment Enabled Successfully';
              }
                this.sucMsg = true;
                this.successMessage;
                this.view_equipment_type();
                setTimeout (() => {  this.sucMsg = false }, 1000);
            }else {
                this.errMsg = true;
                this.router.navigate(['/']);
              
            }
          })
        
      }


 

}

